import "./index.scss";


const TableList = ({ header, allData }) => {
 const headers = header?.map((item, i) => {
    return <th key={i}>{item}</th>;
  });


  return (
    <div className="table-list">
      <div className="table-responsive">
        <table className="table ">
          <thead className="thead-light">
            <tr className="text-center">{headers}</tr>
          </thead>
          <tbody>{allData}</tbody>
        </table>
      </div>
    </div>
  );
};

export default TableList;
