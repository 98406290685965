import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { sideBarActions } from "../../../redux/sideBar/sideBarSlice";

const Analytics = () => {
  const dispatch = useDispatch();

  return (
    <div className="accordion-body">
      <ul className="list-group list-group-flush">
        <li className="list-group-item py-3 px-0 pt-0">
          <h5 className="font-size-13 mb-0">
            <div
              onClick={() => {
                dispatch(
                  sideBarActions.changeOverlay({
                    overlay: "analytics",
                  })
                );
                dispatch(sideBarActions.showOverlay());
              }}
              className="text-body d-block"
              style={{ cursor: "pointer" }}
            >
              Analytics
            </div>
          </h5>
        </li>
        <li className="list-group-item py-3 px-0">
          <h5 className="font-size-13 mb-0">
            <Link to="#" className="text-body d-block">
              Contact
            </Link>
          </h5>
        </li>
      </ul>
    </div>
  );
};

export default Analytics;
