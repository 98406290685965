import React from "react";
import { Row, Col } from "reactstrap";
import LineChart from "./LineChart";
import AppSimpleBar from "../../../../components/AppSimpleBar";
import TopBarResponsive from "../../../../components/TopBarResponsive";
const ShowAnalytics = () => {
  const UserBase = {
    users: [12, 43, 100, 123, 100, 13, 134, 45, 123, 143, 344, 234],
    organizations: [12, 20, 43, 25, 23, 45, 23, 12, 34, 22, 32, 54],
    label1: "Users",
    label2: "Organizations",
  };
  const financial = {
    revenue: [123, 233, 144, 154, 234, 133, 154, 234, 442, 554, 223, 255],
    cost: [15, 6, 223, 43, 64, 35, 65, 76, 50, 95, 33, 87],
    label1: "Revenue",
    label2: "Cost",
  };

  return (
    <>
      <TopBarResponsive />
      <AppSimpleBar className="payment-screen ">
        <div className="card custom-div" style={{ paddingBottom: "0px" }}>
          <AppSimpleBar className="admin-user-card">
            <h4 className="mb-3">Analytics</h4>{" "}
            <Row className="d-flex justify-content-center">
              <Col
                sm={6}
                xxl={3}
                className="mb-3"
                // style={{
                //   boxShadow:
                //     "0px 20px 50px 0px rgba(61.999999999999815, 54.99999999999995, 177.99999999999994, 0.11)",
                //   padding: "25px",
                //   borderRadius: "5px",
                //   width: "250px",
                //   marginRight:"10px"
                // }}
              >
                <div
                  className="mb-3"
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                    padding: "15px",
                    borderRadius: "5px",
                    width: "100%",
                    height: "fit-content",
                  }}
                >
                  <h3 style={{ fontWeight: "bold" }}>$17</h3>
                  <div
                    style={{
                      backgroundColor: "#4f46e5",
                      padding: "3px 10px",
                      borderRadius: "15px",
                      width: "fit-content",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "12px",
                        textTransform: "uppercase",
                        margin: "0",
                        color: "white",
                      }}
                    >
                      Subscription
                    </p>
                  </div>
                </div>
              </Col>

              <Col
                sm={6}
                xxl={3}
                className="mb-3"
                // style={{
                //   boxShadow:
                //     "0px 20px 50px 0px rgba(61.999999999999815, 54.99999999999995, 177.99999999999994, 0.11)",
                //   padding: "25px",
                //   borderRadius: "5px",
                //   width: "250px",
                //   marginRight: "10px",
                // }}
              >
                <div
                  className=" mb-3"
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                    padding: "15px",
                    borderRadius: "5px",
                    width: "100%",
                    height: "fit-content",
                  }}
                >
                  <h3 style={{ fontWeight: "bold" }}>314 </h3>
                  <div
                    style={{
                      backgroundColor: "#4f46e5",
                      padding: "3px 10px",
                      borderRadius: "15px",
                      width: "fit-content",

                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "12px",
                        textTransform: "uppercase",
                        margin: "0",
                        color: "white",
                      }}
                    >
                      Active Users
                    </p>
                  </div>
                </div>
              </Col>
              <Col
                sm={6}
                xxl={3}
                className="mb-3"
                // style={{
                //   boxShadow:
                //     "0px 20px 50px 0px rgba(61.999999999999815, 54.99999999999995, 177.99999999999994, 0.11)",
                //   padding: "25px",
                //   borderRadius: "5px",
                //   width: "250px",
                //   marginRight: "10px",
                // }}
              >
                <div
                  className="mb-3"
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                    padding: "15px",
                    borderRadius: "5px",
                    width: "100%",
                    height: "fit-content",
                  }}
                >
                  <h3 style={{ fontWeight: "bold" }}>$21,000 </h3>
                  <div
                    style={{
                      backgroundColor: "#4f46e5",
                      padding: "3px 10px",
                      borderRadius: "15px",
                      width: "fit-content",

                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "12px",
                        textTransform: "uppercase",
                        margin: "0",
                        color: "white",
                      }}
                    >
                      Total Revenue
                    </p>
                  </div>
                </div>
              </Col>

              <Col
                sm={6}
                xxl={3}
                className="mb-3"
                // style={{
                //   boxShadow:
                //     "0px 20px 50px 0px rgba(61.999999999999815, 54.99999999999995, 177.99999999999994, 0.11)",
                //   padding: "25px",
                //   borderRadius: "5px",
                //   width: "250px",
                //   marginRight: "10px",
                //   height: "fit-content",
                // }}
              >
                <div
                  className="mb-3"
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                    padding: "15px",
                    borderRadius: "5px",
                    width: "100%",
                    height: "fit-content",
                  }}
                >
                  <h3 style={{ fontWeight: "bold" }}>$9,000 </h3>
                  <div
                    style={{
                      backgroundColor: "#4f46e5",
                      padding: "3px 10px",
                      borderRadius: "15px",
                      width: "fit-content",

                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "12px",
                        textTransform: "uppercase",
                        margin: "0",
                        color: "white",
                      }}
                    >
                      Total Cost
                    </p>
                  </div>
                </div>
              </Col>
              <hr style={{ height: "2px" }} />
            </Row>
            <Row className="d-flex justify-content-around">
              <Col sm={12} xxl={6} style={{ marginTop: "20px" }}>
                <h6>User Base</h6>
                {/* <div className="d-flex  " style={{ height: "500px",width: "fit-content"}}> */}
                <div
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                    padding: "25px",
                    borderRadius: "5px",
                    width: "100%",
                    height: "400px",
                    display:"flex",
                    justifyContent:"center"
                  }}
                >
                  <LineChart
                    dataValues1={UserBase.users}
                    dataValues2={UserBase.organizations}
                    label1={UserBase.label1}
                    label2={UserBase.label2}
                    currencyAppear={false}
                  />
                </div>
                {/* </div> */}
              </Col>
              <Col sm={12} xxl={6} style={{ marginTop: "20px" }}>
                <h6>Financial</h6>
                {/* <div className="d-flex  " style={{ height: "500px" , width: "fit-content",}}> */}
                <div
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                    padding: "25px",
                    borderRadius: "5px",
                    width: "100%",
                    height: "400px",
                    display:"flex",
                    justifyContent:"center"
                  }}
                >
                  <LineChart
                    dataValues1={financial.revenue}
                    dataValues2={financial.cost}
                    label1={financial.label1}
                    label2={financial.label2}
                    currencyAppear={true}
                  />
                </div>
                {/* </div> */}
              </Col>
            </Row>
            {/* </Col> */}
          </AppSimpleBar>
        </div>
      </AppSimpleBar>
    </>
  );
};

export default ShowAnalytics;
