import { createSlice } from "@reduxjs/toolkit";

const sideBarSlice = createSlice({
  name: "sideBar",
  initialState: {
    tab: "",
    overlay: "",
    showOverlay: false,
    chooseBot: false,
    historyChat: false,
  },
  reducers: {
    changeTab(state, action) {
      const tabSelected = action.payload.tab;
      state.tab = tabSelected;
    },
    changeOverlay(state, action) {
      const overlay = action.payload.overlay;
      state.overlay = overlay;
    },
    showOverlay(state) {
      state.showOverlay = true;
    },
    hideOverlay(state) {
      state.showOverlay = false;
    },
    chooseBot(state, action) {
      const status = action.payload;
      state.chooseBot = status;
    },
    historyChat(state, action) {
      const chatStatus = action.payload;
      state.historyChat = chatStatus;
    },
  },
});

export const sideBarActions = sideBarSlice.actions;
export default sideBarSlice;
