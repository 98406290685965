import React from "react";
import { Navigate } from "react-router-dom";

// import { useProfile } from "../hooks/index";

const AuthProtected = (props) => {
  //   const { userProfile, loading } = useProfile();
  const userProfile = {
    email: "admin@themesbrand.com",
    password: "123456",
    profileImage: "data:image/jpeg;base64,/9j/4QAYRXhpZgAASUkqAAgAAA",
    role: "admin",
    uid: 1,
    username: "admin",
  };
  if (!userProfile) {
    return (
      <Navigate to={{ pathname: "/auth-login" }} />
    );
  }

  return <>{props.children}</>;
};

export { AuthProtected };
