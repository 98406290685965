import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

const SliderUsers = ({ getVal, max, step }) => {
  return (
    <Box>
      <Slider
        aria-label="Temperature"
        defaultValue={0}
        getAriaValueText={getVal}
        valueLabelDisplay="auto"
        step={step}
        marks
        min={0}
        max={max}
      />
    </Box>
  );
};

export default SliderUsers;
