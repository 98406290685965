import React, { useState } from "react";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";

// components
import LightBox from "../../../components/LightBox";

//images
import imagePlaceholder from "../../../assets/images/users/profile-placeholder.png";

// interface
// import {
//   MessagesTypes,
//   ImageTypes,
//   AttachmentTypes,
// } from "../../../data/messages";

// hooks
// import { useProfile } from "../../../hooks";

// utils
// import { formateDate } from "../../../utils";
import RepliedMessage from "./RepliedMessage";

// const Menu = ({ onDelete, onReply, onForward }) => {
//   return (
//     <UncontrolledDropdown className="align-self-start message-box-drop">
//       <DropdownToggle className="btn btn-toggle" role="button" tag={"a"}>
//         <i className="ri-more-2-fill"></i>
//       </DropdownToggle>
//       <DropdownMenu>
//         <DropdownItem
//           className="d-flex align-items-center justify-content-between"
//           to="#"
//           onClick={onReply}
//         >
//           Reply <i className="bx bx-share ms-2 text-muted"></i>
//         </DropdownItem>
//         <DropdownItem
//           className="d-flex align-items-center justify-content-between"
//           to="#"
//           onClick={onForward}
//         >
//           Forward <i className="bx bx-share-alt ms-2 text-muted"></i>
//         </DropdownItem>
//         <DropdownItem
//           className="d-flex align-items-center justify-content-between"
//           to="#"
//         >
//           Copy <i className="bx bx-copy text-muted ms-2"></i>
//         </DropdownItem>
//         <DropdownItem
//           className="d-flex align-items-center justify-content-between"
//           to="#"
//         >
//           Bookmark <i className="bx bx-bookmarks text-muted ms-2"></i>
//         </DropdownItem>
//         <DropdownItem
//           className="d-flex align-items-center justify-content-between"
//           to="#"
//         >
//           Mark as Unread <i className="bx bx-message-error text-muted ms-2"></i>
//         </DropdownItem>
//         <DropdownItem
//           className="d-flex align-items-center justify-content-between delete-item"
//           onClick={onDelete}
//         >
//           Delete <i className="bx bx-trash text-muted ms-2"></i>
//         </DropdownItem>
//       </DropdownMenu>
//     </UncontrolledDropdown>
//   );
// };

// const ImageMoreMenu = ({ imagelink, onReply, onDelete }) => {
//   return (
//     <div className="message-img-link">
//       <ul className="list-inline mb-0">
//         <UncontrolledDropdown
//           tag="li"
//           color="none"
//           className="list-inline-item dropdown"
//         >
//           <DropdownToggle tag="a" role="button" className="btn btn-toggle">
//             <i className="bx bx-dots-horizontal-rounded"></i>
//           </DropdownToggle>
//           <DropdownMenu>
//             <DropdownItem
//               className="dropdown-item d-flex align-items-center justify-content-between"
//               href={imagelink}
//               download
//             >
//               Download <i className="bx bx-download ms-2 text-muted"></i>
//             </DropdownItem>
//             <DropdownItem
//               tag="a"
//               className=" d-flex align-items-center justify-content-between"
//               href="#"
//               onClick={onReply}
//             >
//               Reply <i className="bx bx-share ms-2 text-muted"></i>
//             </DropdownItem>
//             <DropdownItem
//               tag="a"
//               className=" d-flex align-items-center justify-content-between"
//               href="#"
//               data-bs-toggle="modal"
//               data-bs-target=".forwardModal"
//             >
//               Forward <i className="bx bx-share-alt ms-2 text-muted"></i>
//             </DropdownItem>
//             <DropdownItem
//               tag="a"
//               className=" d-flex align-items-center justify-content-between"
//               href="#"
//             >
//               Bookmark <i className="bx bx-bookmarks text-muted ms-2"></i>
//             </DropdownItem>
//             <DropdownItem
//               tag="a"
//               className=" d-flex align-items-center justify-content-between delete-item"
//               href="#"
//               onClick={onDelete}
//             >
//               Delete <i className="bx bx-trash ms-2 text-muted"></i>
//             </DropdownItem>
//           </DropdownMenu>
//         </UncontrolledDropdown>
//       </ul>
//     </div>
//   );
// };

// const Image = ({
//   message,
//   image,
//   onImageClick,
//   index,
//   onSetReplyData,
//   // onDeleteImg,
// }) => {
//   const onDelete = () => {
//     // onDeleteImg(image.id);
//   };
//   const onClickReply = () => {
//     let multiimages = message["image"];

//     let results = multiimages.filter(
//       (multiimage) => multiimage.id === image.id
//     );

//     message["newimage"] = results;

//     onSetReplyData(message);
//   };
//   return (
//     <React.Fragment>
//       <div className="message-img-list">
//         <div>
//           <Link
//             className="popup-img d-inline-block"
//             to={"#"}
//             onClick={() => onImageClick(index)}
//           >
//             <img src={image.downloadLink} alt="" className="rounded border" />
//           </Link>
//         </div>
//         <ImageMoreMenu
//           imagelink={image.downloadLink}
//           // onReply={onClickReply}
//           onDelete={onDelete}
//         />
//       </div>
//     </React.Fragment>
//   );
// };

// const Images = ({ message, images, onSetReplyData, onDeleteImg }) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [selected, setSelected] = useState(0);
//   const onImageClick = () => {
//     // setSelected(id);
//     setIsOpen(true);
//   };
//   const onClose = () => {
//     setIsOpen(false);
//   };

//   return (
//     <>
//       <div className="message-img mb-0">
//         {(images || []).map((image, key) => (
//           <Image
//             message={message}
//             image={image}
//             key={key}
//             index={key}
//             onImageClick={onImageClick}
//             onSetReplyData={onSetReplyData}
//             // onDeleteImg={onDeleteImg}
//           />
//         ))}
//       </div>
//       {isOpen && (
//         <LightBox
//           isOpen={isOpen}
//           images={images}
//           onClose={onClose}
//           defaultIdx={selected}
//         />
//       )}
//     </>
//   );
// };

// const Attachments = ({ attachments }) => {
//   return (
//     <>
//       {(attachments || []).map((attachment, key) => (
//         <div
//           key={key}
//           className={classnames("p-3", "border-primary", "border rounded-3", {
//             "mt-2": key !== 0,
//           })}
//         >
//           <div className="d-flex align-items-center attached-file">
//             <div className="flex-shrink-0 avatar-sm me-3 ms-0 attached-file-avatar">
//               <div className="avatar-title bg-soft-primary text-primary rounded-circle font-size-20">
//                 <i className="ri-attachment-2"></i>
//               </div>
//             </div>
//             <div className="flex-grow-1 overflow-hidden">
//               <div className="text-start">
//                 <h5 className="font-size-14 mb-1">{attachment.name}</h5>
//                 <p className="text-muted text-truncate font-size-13 mb-0">
//                   {attachment.desc}
//                 </p>
//               </div>
//             </div>
//             <div className="flex-shrink-0 ms-4">
//               <div className="d-flex gap-2 font-size-20 d-flex align-items-start">
//                 <div>
//                   <a
//                     href={
//                       attachment.downloadLink ? attachment.downloadLink : "#"
//                     }
//                     className="text-muted"
//                     download
//                   >
//                     <i className="bx bxs-download"></i>
//                   </a>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       ))}
//     </>
//   );
// };

// const Typing = () => {
//   return (
//     <p className="mb-0">
//       typing
//       <span className="animate-typing">
//         <span className="dot mx-1"></span>
//         <span className="dot me-1"></span>
//         <span className="dot"></span>
//       </span>
//     </p>
//   );
// };

const Message = ({
  message,
  chatUserDetails,
  onDelete,
  onSetReplyData,
  isFromMe,
  onOpenForward,
  isChannel,
  onDeleteImage,
}) => {
  // const myId = 1;

  // let conversations = [
  //   {
  //     conversationId: 1,
  //     userId: "614ecab4ac946a9bdafa4e3b",
  //     typingUser: "614ecab4ac946a9bdafa4e3b",
  //     messages: [
  //       {
  //         mId: 1,
  //         text: "Good morning 😊",
  //         time: new Date().toISOString(),
  //         meta: {
  //           receiver: myId,
  //           sender: "614ecab4ac946a9bdafa4e3b",
  //           sent: true,
  //           received: true,
  //           read: true,
  //         },
  //       },
  //       {
  //         mId: 2,
  //         text: "Good morning, How are you? What about our next meeting?",
  //         time: new Date().toISOString(),
  //         meta: {
  //           receiver: "614ecab4ac946a9bdafa4e3b",
  //           sender: myId,
  //           sent: true,
  //           received: true,
  //           read: true,
  //         },
  //       },
  //       {
  //         mId: 3,
  //         text: "Yeah everything is fine. Our next meeting tomorrow at 10.00 AM",
  //         time: new Date().toISOString(),
  //         meta: {
  //           receiver: myId,
  //           sender: "614ecab4ac946a9bdafa4e3b",
  //           sent: true,
  //           received: true,
  //           read: true,
  //         },
  //       },
  //       {
  //         mId: 4,
  //         text: "Hey, I'm going to meet a friend of mine at the department store. I have to buy some presents for my parents 🎁",
  //         time: new Date().toISOString(),
  //         meta: {
  //           receiver: myId,
  //           sender: "614ecab4ac946a9bdafa4e3b",
  //           sent: true,
  //           received: true,
  //           read: true,
  //         },
  //       },
  //       {
  //         mId: 5,
  //         text: "Wow that's great",
  //         time: new Date().toISOString(),
  //         meta: {
  //           receiver: "614ecab4ac946a9bdafa4e3b",
  //           sender: myId,
  //           sent: true,
  //           received: true,
  //           read: true,
  //         },
  //       },
  //       {
  //         mId: 6,
  //         time: new Date().toISOString(),
  //         meta: {
  //           receiver: myId,
  //           sender: "614ecab4ac946a9bdafa4e3b",
  //           sent: true,
  //           received: true,
  //           read: true,
  //         },
  //         image: [
  //           {
  //             id: 1,
  //             downloadLink: "img1",
  //           },
  //           {
  //             id: 2,
  //             downloadLink: "img2",
  //           },
  //         ],
  //       },
  //       {
  //         mId: 7,
  //         time: new Date().toISOString(),
  //         meta: {
  //           receiver: "614ecab4ac946a9bdafa4e3b",
  //           sender: myId,
  //           sent: true,
  //           received: true,
  //           read: true,
  //         },
  //         attachments: [
  //           {
  //             id: 1,
  //             name: "design-phase-1-approved.pdf",
  //             downloadLink: "",
  //             desc: "12.5 MB",
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     conversationId: 2,
  //     userId: "61665bcb9a456823e282afa7",
  //     typingUser: "contacts[0].id",
  //     isGroupConversation: true,
  //     messages: [
  //       {
  //         mId: 1,
  //         text: "Good morning Everyone",
  //         time: new Date().toISOString(),
  //         meta: {
  //           receiver: myId,
  //           sender: "contacts[0].id",
  //           userData:" contacts[0]",
  //           sent: true,
  //           received: true,
  //           read: true,
  //         },
  //       },
  //       {
  //         mId: 2,
  //         text: "Good morning, How are you? What about our next meeting?",
  //         time: new Date().toISOString(),
  //         meta: {
  //           receiver: "contacts[0].id",
  //           sender: myId,
  //           userData: "contacts[0]",
  //           sent: true,
  //           received: true,
  //           read: true,
  //         },
  //       },
  //       {
  //         mId: 3,
  //         text: "Yeah everything is fine, Our next meeting tomorrow at 10.00 am AM",
  //         time: new Date().toISOString(),
  //         meta: {
  //           receiver: myId,
  //           sender:" contacts[1].id",
  //           userData: "contacts[1]",
  //           sent: true,
  //           received: true,
  //           read: true,
  //         },
  //       },
  //       {
  //         mId: 4,
  //         text: "Wow that's great",
  //         time: new Date().toISOString(),
  //         meta: {
  //           receiver: myId,
  //           sender: "contacts[2].id",
  //           userData: "contacts[2]",
  //           sent: true,
  //           received: true,
  //           read: true,
  //         },
  //       },
  //       {
  //         mId: 5,
  //         text: "@Jean Berwick, Please Assign AB-123 to me",
  //         time: new Date().toISOString(),
  //         meta: {
  //           receiver: "contacts[0].id",
  //           sender: myId,
  //           userData: "contacts[0]",
  //           sent: true,
  //           received: true,
  //           read: true,
  //         },
  //       },
  //       {
  //         mId: 6,
  //         text: "Okay, Sure",
  //         time: new Date().toISOString(),
  //         meta: {
  //           receiver: myId,
  //           sender: "contacts[3].id",
  //           userData: "contacts[3]",
  //           sent: true,
  //           received: true,
  //           read: true,
  //         },
  //       },
  //     ],
  //   },
  // ];
  // const { userProfile } = useProfile();
  // const hasImages = message.image && message.image.length;
  // const hasAttachments = message.attachments && message.attachments.length;
  // const hasText = message.text;
  // const isTyping = false;

  // const chatUserFullName = chatUserDetails.firstName
  //   ? `${chatUserDetails.firstName} ${chatUserDetails.lastName}`
  //   : "-";

  // const myProfile = userProfile.profileImage
  //   ? userProfile.profileImage
  //   : imagePlaceholder;
  // const channeluserProfile =
  //   message.meta.userData && message.meta.userData.profileImage
  //     ? message.meta.userData.profileImage
  //     : imagePlaceholder;
  // const chatUserprofile = chatUserDetails.profileImage
  //   ? chatUserDetails.profileImage
  //   : imagePlaceholder;
  // const profile = isChannel ? channeluserProfile : chatUserprofile;
  // const date = formateDate(message.time, "hh:mmaaa");
  // const isSent = message.meta.sent;
  // const isReceived = message.meta.received;
  // const isRead = message.meta.read;
  // const isForwarded = message.meta.isForwarded;
  // const channdelSenderFullname = message.meta.userData
  //   ? `${message.meta.userData.firstName} ${message.meta.userData.lastName}`
  //   : "-";
  // const fullName = isChannel ? channdelSenderFullname : chatUserFullName;
  // const onDeleteMessage = () => {
  //   onDelete(message.mId);
  // };

  // const onClickReply = () => {
  //   onSetReplyData(message);
  // };
  // const isRepliedMessage = message.replyOf;

  // const onForwardMessage = () => {
  //   onOpenForward(message);
  // };

  // const onDeleteImg = (imageId) => {
  //   onDeleteImage(message.mId, imageId);
  // };

  return (
    <li
      className={classnames(
        "chat-list ",
        { right: isFromMe }
        // { reply: isRepliedMessage }
      )}
    >
      <div className="conversation-list">
        <div className="chat-avatar">
          {/* <img src={isFromMe ? myProfile : profile} alt="" /> */}
        </div>

        <div className="user-chat-content">
          {
            // hasImages &&
            // message.text
            true && (
              <div className="ctext-wrap">
                <div className="ctext-wrap-content">
                  <p className="mb-0 ctext-content">{message}</p>
                </div>
              </div>
            )
          }
          {/* {
            // isForwarded
            true && (
              <span
                className={classnames(
                  "me-1",
                  "text-muted",
                  "font-size-13",
                  "mb-1",
                  "d-block"
                )}
              >
                <i
                  className={classnames(
                    "ri",
                    "ri-share-forward-line",
                    "align-middle",
                    "me-1"
                  )}
                ></i>
                Forwarded
              </span>
            )
          } */}

          <div className="ctext-wrap">
            {/* text message end */}

            {/* image message start */}
            {
              // hasImages
              true ? (
                <>
                  {/* <Images
                    images={message.image!}
                    message={message}
                    onSetReplyData={onSetReplyData}
                    onDeleteImg={onDeleteImg}
                  /> */}
                </>
              ) : (
                <>
                  <div className="ctext-wrap-content">
                    {
                      // isRepliedMessage
                      true && (
                        <RepliedMessage
                          // fullName={fullName}
                          message={"message"}
                          isFromMe={isFromMe}
                        />
                      )
                    }

                    {
                      // hasText
                      true && (
                        <p className="mb-0 ctext-content">{message.text}</p>
                      )
                    }

                    {/* typing start */}
                    {
                      // isTyping
                      // true && <Typing />
                    }

                    {/* typing end */}
                    {/* files message start */}
                    {
                      // hasAttachments
                      // true && <Attachments attachments={message.attachments} />
                    }
                    {/* files message end */}
                  </div>
                  {/* <Menu
                  onForward={onForwardMessage}
                  onDelete={onDeleteMessage}
                  onReply={onClickReply}
                  /> */}
                </>
              )
            }

            {/* image message end */}
          </div>
        </div>
      </div>
    </li>
  );
};

export default Message;
