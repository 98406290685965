import { TabView, TabPanel } from "primereact/tabview";
import ManegeData from "./ManageData";
import ManageDashboard from "./manageDashboard";
import Settings from "./Settings";
// import AddButton from "../../../components/AddButton";
import TopBarResponsive from "../../../components/TopBarResponsive";
import AppSimpleBar from "../../../components/AppSimpleBar";
import ChatHistory from "./ChatHistory";
const ManageData = () => {
  return (
    <>
      <TopBarResponsive />
      <AppSimpleBar className="payment-screen ">
        <div className="custom-div-managedata ">
          <TabView>
            <TabPanel className="pt-3 tap-font-size" header="Dashboard">
              <ManageDashboard />
            </TabPanel>
            <TabPanel className="pt-3 tap-font-size" header="Manage Data">
              <ManegeData />
            </TabPanel>
            <TabPanel className="pt-3 tap-font-size" header="Settings">
              <Settings />
            </TabPanel>

            <TabPanel className="pt-3 px-0 tap-font-size" header="Chat History">
              <ChatHistory />
            </TabPanel>
          </TabView>
        </div>
      </AppSimpleBar>
    </>
  );
};

export default ManageData;
