import React from "react";
import { Link } from "react-router-dom";
import AuthHeader from "../../../components/AuthHeader";
import NonAuthLayoutWrapper from "../../../components/NonAutnLayoutWrapper";
import Loader from "../../../components/Loader";
import { Alert, Row, Col, Form, Button, Input, Label } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
const RecoverPassword = () => {
  const validation = useFormik({
    initialValues: {
      email: "",
    },
    enableReinitialize: false,
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Invalid email format")
        .email("Please Enter Valid Email")
        .required("Please Enter E-mail."),
    }),
    onSubmit: (values) => {
      console.log({ email: values.email });
    },
  });
  return (
    <NonAuthLayoutWrapper>
      <Row className=" justify-content-center my-auto">
        <Col sm={8} lg={6} xl={5} className="col-xxl-4">
          <div className="py-md-5 py-4">
            <AuthHeader
              title="Reset Password"
              subtitle="Reset Password with Beky.ai."
            />
            {/* 
            {forgetError && forgetError ? (
              <Alert color="danger">{forgetError}</Alert>
            ) : null}
            {forgetSuccessMsg ? (
              <Alert color="success">{forgetSuccessMsg}</Alert>
            ) : null}
            {!forgetError && !forgetSuccessMsg && (
              <Alert color="info" className="text-center my-4">
                Enter your Email and instructions will be sent to you!
              </Alert>
            )} */}
            <Alert color="info" className="text-center my-4">
              Enter your Email and instructions will be sent to you!
            </Alert>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              className="position-relative"
            >
              {/* {forgetPassLoading && <Loader />} */}

              <Label htmlFor="Password" className="form-label">
                Email
              </Label>
              <div className="mb-3">
                <Input
                  label="Email"
                  type="text"
                  name="email"
                  placeholder="Enter Email"
                  className="form-control"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                />
              </div>
              {validation.touched.email && validation.errors.email ? (
                <p style={{ color: "red" }}>{validation.errors.email}</p>
              ) : null}
              <div className="text-center mt-4">
                <Button color="primary" className="w-100" type="submit">
                  Reset
                </Button>
              </div>
            </Form>
            <div className="mt-5 text-center text-muted">
              <p>
                Remember It?{" "}
                <Link
                  to="/auth-login"
                  className="fw-medium text-decoration-underline"
                >
                
                  Login
                </Link>
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </NonAuthLayoutWrapper>
  );
};

export default RecoverPassword;
