import React from "react";
import { TabView, TabPanel } from "primereact/tabview";
import Subpscription from "./subpscription";
import AdminsUsers from "./AdminsUsers";
import OverView from "./OverView";
import TopBarResponsive from "../../../components/TopBarResponsive";
import AppSimpleBar from "../../../components/AppSimpleBar";
function SuperAdminUsers() {
  return (
    <>
      <TopBarResponsive />
      <AppSimpleBar className="payment-screen ">
        <div className="custom-div-managedata ">
          <TabView>
            <TabPanel className="pt-3" header="Overview">
              <OverView />
            </TabPanel>
            <TabPanel className="pt-3" header="Users">
              <AdminsUsers />
            </TabPanel>

            <TabPanel className="pt-3" header="Subscription">
              <Subpscription />
            </TabPanel>
          </TabView>
        </div>
      </AppSimpleBar>
    </>
  );
}

export default SuperAdminUsers;
