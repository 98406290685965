import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

const InviteContactModal = ({ isOpen, onClose, onInvite }) => {
  const [valid, setValid] = useState(false);
  const validation = useFormik({
    initialValues: {
      email: "",
    },
    enableReinitialize: false,
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Invalid email format")
        .email("Please Enter Valid Email")
        .required("Please Enter E-mail."),
    }),
    onSubmit: (values) => {
      console.log({ mail: values.email });
    },
  });

  return (
    <Modal isOpen={isOpen} toggle={onClose} tabIndex={-1} centered scrollable>
      <ModalHeader className="modal-title-custom" toggle={onClose}>
        Invite User
      </ModalHeader>
      <ModalBody className="p-4">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          className="position-relative"
        >
          {/* {loginLoading && <Loader />} */}
          <Label htmlFor="email" className="form-label">
            Email
          </Label>
          <div className="mb-3">
            <Input
              label="Username"
              type="text"
              name="email"
              placeholder="Enter Email"
              className="form-control"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.email || ""}
            />
            {validation.touched.email && validation.errors.email ? (
              <p style={{ color: "red" }}>{validation.errors.email}</p>
            ) : null}
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="link" className="btn" onClick={onClose}>
          Close
        </Button>
        <Button
          type="button"
          color="primary"
          disabled={!valid}
          onClick={onClose}
        >
          Invite
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default InviteContactModal;
