import React, { useState } from "react";
import TableList from "../../../../components/TableList/index";
import TablePagination from "../../../../components/Pagination";
import UserImage from "../../../../assets/images/users/avatar-1.jpg";
import { Input } from "reactstrap";
import { Calendar } from "primereact/calendar";
import UsersPopup from "../../../../components/UsersPopup";
import DropDown from "../../../../components/DropDown/index";
const AdminsUsers = () => {
  const [filterVisabilty, setFilterVisabilty] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [dates, setDates] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  let filteredData = (users) => {
    setCurrentItems(users);
  };
  const header = [ "      ","Role", "Name", "Messages", "Tokens", "Actions"];

  const data = [
    {
      name: "Fady",
      userStatus: "Admin",
      msgCount: "50",
      tokens: "594",
    },
    {
      name: "Adam",
      userStatus: "Admin",
      msgCount: "50",
      tokens: "123",
    },
    {
      name: "Fatma",
      userStatus: "User",
      msgCount: "50",
      tokens: "566",
    },

    {
      name: "sayed",
      userStatus: "User",
      msgCount: "50",
      tokens: "34",
    },
    {
      name: "gmal",
      userStatus: "User",
      msgCount: "50",
      tokens: "49",
    },
    {
      name: "ahmed",
      userStatus: "User",
      msgCount: "50",
      tokens: "234",
    },
    {
      name: "Younes",
      userStatus: "User",
      msgCount: "50",
      tokens: "599",
    },
    {
      name: "ali",
      userStatus: "User",
      msgCount: "50",
      tokens: "100",
    },
  ];

  const allData = currentItems?.map((item, i) => {
    return (
      <tr key={i} className="text-center">
        <td>
          <ul className="list-unstyled team-info">
            <li>
              <img alt="Avatar" src={UserImage} />
            </li>
          </ul>
        </td>
        <td className="project-title" style={{width:"150px"}}>
          <h6>{item.userStatus}</h6>
        </td>
        
        <td  style={{width:"150px"}}>
          <h6>{item.name}</h6>
        </td>

        <td className="msgs">
          <h6>{item.msgCount}</h6>
        </td>

        <td className="token">
          <h6>{item.tokens}</h6>
        </td>

        {/* <td>{badgePriorty(item.priorty)}</td> */}

        <td className="project-actions">
          <div className="d-flex gap-2 justify-content-center align-items-center">
            <i
              className="bx bx-show"
              style={{ cursor: "pointer" }}
              onClick={openModal}
            ></i>
            <DropDown user="user" />

            {/* <i className="bx bxs-pencil"></i>

            <i className="bx bxs-trash"></i> */}
          </div>
        </td>
      </tr>
    );
  });
  return (
    <>
      {isOpen && (
        <UsersPopup
          isOpen={isOpen}
          onClose={closeModal}
          // onInvite={onInviteContact}
        />
      )}

      <div className=" d-flex justify-content-end">
        <div
          className="btn btn-outline-primary"
          style={{ padding: "5px 8px", margin: "0", margin: "10px" }}
          onClick={() => setFilterVisabilty(!filterVisabilty)}
        >
          <i className="bx bx-filter-alt "></i>
        </div>
      </div>
      {filterVisabilty && (
        <div className="row row-cols-3 px-4 mb-3 ">
          <div className="col">
            {" "}
            <Input
              type="text"
              name="email"
              placeholder="Enter user name"
              className="form-control"
            />
          </div>
          <div className="col">
            {" "}
            <Input
              type="text"
              name="email"
              placeholder="Enter user role"
              className="form-control"
            />
          </div>
          <div className="card flex justify-content-center">
            <Calendar
              value={dates}
              onChange={(e) => setDates(e.value)}
              selectionMode="range"
              readOnlyInput
              placeholder="Enter date range"
              style={{
                height: "38px",
                borderadius: "0.25rem",
                borderColor: "#e6ebf5 !important",
              }}
            />
          </div>
        </div>
      )}
      <TableList header={header} allData={allData} />
      <TablePagination data={data} filterData={filteredData} />
    </>
  );
};

export default AdminsUsers;
