import React, { useEffect, useState } from "react";

import { Button, Form, Input, UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
// hooks
// import { useRedux } from "../../../hooks/index";

// components
import AppSimpleBar from "../../../components/AppSimpleBar";
import AddGroupModal from "../../../components/AddGroupModal";
import InviteContactModal from "../../../components/InviteContactModal";

import AddButton from "../../../components/AddButton";
import ContactModal from "../../../components/ContactModal";

import Chanels from "./Chanels";
// import { CHATS_TABS } from "../../../constants";

const Index = (props) => {
  // global store
  // const { dispatch, useAppSelector } = useRedux();

  // const {
  //   isContactInvited,

  //   channels,
  //   isContactsAdded,
  //   isChannelCreated,
  //   selectedChat,
  //   isFavouriteContactToggled,
  //   chatUserDetails,
  // } = useAppSelector((state) => ({
  //   isContactInvited: state.Contacts.isContactInvited,
  //   channels: state.Chats.channels,
  //   isContactsAdded: state.Chats.isContactsAdded,
  //   isChannelCreated: state.Chats.isChannelCreated,
  //   selectedChat: state.Chats.selectedChat,
  //   isFavouriteContactToggled: state.Chats.isFavouriteContactToggled,
  //   chatUserDetails: state.Chats.chatUserDetails,
  // }));
  /*
  get data
  */
  // useEffect(() => {
  //   dispatch(getChannels());
  // }, [dispatch]);

  /*
  invite contact modal handeling
  */
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  /*
  onInvite handeling
  */
  const onInviteContact = (data) => {
    // dispatch(inviteContact(data));
  };
  // useEffect(() => {
  //   if (isContactInvited) {
  //     setIsOpen(false);
  //     setTimeout(() => {
  //       dispatch(resetContacts("isContactInvited", false));
  //     }, 1000);
  //   }
  // }, [dispatch, isContactInvited]);

  /*
  contact add handeling
  */
  const [isOpenAddContact, setIsOpenAddContact] = useState(false);
  const openAddContactModal = () => {
    setIsOpenAddContact(true);
  };
  const closeAddContactModal = () => {
    setIsOpenAddContact(false);
  };
  const onAddContact = (contacts) => {
    // dispatch(addContacts(contacts));
  };
  // useEffect(() => {
  //   if (isContactsAdded) {
  //     setIsOpenAddContact(false);
  //   }
  // }, [dispatch, isContactsAdded]);

  /*
  channel creation handeling
  */
  const [isOpenCreateChannel, setIsOpenCreateChannel] = useState(false);
  const openCreateChannelModal = () => {
    setIsOpenCreateChannel(true);
  };
  const closeCreateChannelModal = () => {
    setIsOpenCreateChannel(false);
  };
  const onCreateChannel = (channelData) => {
    // dispatch(createChannel(channelData));
  };
  // useEffect(() => {
  //   if (isChannelCreated) {
  //     setIsOpenCreateChannel(false);
  //     dispatch(getChannels());
  //   }
  // }, [dispatch, isChannelCreated]);

  /*
  select chat handeling :
    get conversations
    get chat user details
  */

  const onSelectChat = (id, isChannel) => {
    if (isChannel) {
      // dispatch(getChannelDetails(id));
    } else {
      // dispatch(getChatUserDetails(id));
    }
    // dispatch(readConversation(id));
    // dispatch(getChatUserConversations(id));
    // dispatch(changeSelectedChat(id));
  };

  /*
  tab handeling
  */
  const [active, setActive] = useState("default");
  // const onChangeTab = (tab) => {
  //   setActive(tab);
  // };

  //serach recent user
  const searchUsers = () => {
    var li, a, i, txtValue;
    const inputValue = document.getElementById("serachChatUser");
    const filter = inputValue.value.toUpperCase();
    const ul = document.querySelector(".chat-room-list");
    li = ul.getElementsByTagName("li");
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  };

  return (
    <>
      <div>
        <div className="px-4 pt-4">
          <div className="d-flex align-items-start">
            <div className="flex-grow-1">
              <h4 className="mb-4">Chats</h4>
            </div>
            <div className="flex-shrink-0">
              <div id="add-contact">
                {/* Button trigger modal */}
                <AddButton />
              </div>
              <UncontrolledTooltip target="add-contact" placement="bottom">
                New Chat
              </UncontrolledTooltip>
            </div>
          </div>
          <Form>
            <div className="input-group mb-3">
              <Input
                onKeyUp={searchUsers}
                id="serachChatUser"
                type="text"
                className="form-control bg-light border-0 pe-0"
                placeholder="Search here.."
              />
              <Button color="light" type="button" id="searchbtn-addon">
                <i className="bx bx-search align-middle"></i>
              </Button>
            </div>
          </Form>
        </div>{" "}
        {/* .p-4 */}
        <AppSimpleBar className="chat-room-list">
          <>
            <Chanels
              // channels={channels}
              openCreateChannel={openCreateChannelModal}
              // selectedChat={selectedChat}
              // onSelectChat={onSelectChat}
            />
          </>

          {/* End chat-message-list */}
        </AppSimpleBar>
      </div>
      {/* add group Modal */}
      {isOpenCreateChannel && (
        <AddGroupModal
          isOpen={isOpenCreateChannel}
          onClose={closeCreateChannelModal}
          onCreateChannel={onCreateChannel}
        />
      )}

      {/* add contact modal */}
      {isOpen && (
        <InviteContactModal
          isOpen={isOpen}
          onClose={closeModal}
          onInvite={onInviteContact}
        />
      )}

      {isOpenAddContact && (
        <ContactModal
          isOpen={isOpenAddContact}
          onClose={closeAddContactModal}
          onAddContact={onAddContact}
        />
      )}
    </>
  );
};

export default Index;
