import React, { useState } from "react";
import TableList from "../../../components/TableList";
import TablePagination from "../../../components/Pagination";
import UserImage from "../../../assets/images/users/avatar-1.jpg";
import { useDispatch } from "react-redux";
import { sideBarActions } from "../../../redux/sideBar/sideBarSlice";
const ChatHistory = () => {
  const dispatch = useDispatch();
  const [currentItems, setCurrentItems] = useState([]);
  const [dates, setDates] = useState(null);

  const header = ["Date/Time", "      ", "Name", "Questions"];

  const data = [
    {
      name: "Fady",
      Date: "12 Feb 2023",
      questions: "5",
    },
    {
      name: "Mohamed Aly",
      Date: "12 Feb 2023",
      questions: "5",
    },
    {
      name: "Salma sayed",
      Date: "12 Feb 2023",
      questions: "5",
    },
    {
      name: "Fady",
      Date: "12 Feb 2023",
      questions: "5",
    },
    {
      name: "Fady",
      Date: "12 Feb 2023",
      questions: "5",
    },
    {
      name: "Fady",
      Date: "12 Feb 2023",
      questions: "5",
    },
    {
      name: "Magdy Ahmed",
      Date: "12 Feb 2023",
      questions: "5",
    },
    {
      name: "Fady",
      Date: "12 Feb 2023",
      questions: "5",
    },
  ];

  let filteredData = (users) => {
    setCurrentItems(users);
  };

  const allData = currentItems?.map((item, i) => {
    return (
      <tr
        key={i}
        className="text-center hover-tr-effect"
        onClick={() => {
          dispatch(sideBarActions.historyChat(true));
        }}
      >
        <td className="project-title">
          <h6>{item.Date}</h6>
        </td>
        <td>
          <ul className="list-unstyled team-info">
            <li>
              <img alt="Avatar" src={UserImage} />
            </li>
          </ul>
        </td>
        <td width={"200px"}>
          <h6>{item.name}</h6>
        </td>

        <td className="msgs">
          <h6>{item.questions}</h6>
        </td>
      </tr>
    );
  });
  return (
    <div className="py-4">
      <TableList header={header} allData={allData} />
      <TablePagination data={data} filterData={filteredData} />
    </div>
  );
};

export default ChatHistory;
