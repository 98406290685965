import React, { useState } from "react";

import { Button, UncontrolledTooltip, PopoverBody, Popover } from "reactstrap";

// emoji picker
// import Picker from "emoji-picker-react";

const StartButtons = ({ onToggle, onChange, text }) => {
  /*
  emoji handeling
  */
  const [isOpen, setIsOpen] = useState(false);
  const onToggleEmoji = () => {
    setIsOpen(!isOpen);
  };

  const onEmojiClick = (event, emojiObject) => {
    onChange(text + emojiObject.emoji);
  };

  return (
    <div className="chat-input-links me-md-2">
      <div className="links-list-item" id="more-menu">
        <Button
          type="button"
          className="btn btn-link text-decoration-none btn-lg waves-effect"
          onClick={onToggle}
          color="none"
        >
          <i className="bx bx-dots-horizontal-rounded align-middle"></i>
        </Button>
      </div>
      <UncontrolledTooltip target="more-menu" placement="top">
        More
      </UncontrolledTooltip>
    </div>
  );
};

export default StartButtons;
