import React,{ useState } from "react";
import { Link } from "react-router-dom";
import img1 from "../../../assets/images/users/avatar-4.jpg";

import {
  Form,
  Input,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
} from "reactstrap";
import AddButton from "../../../components/AddButton";
import InviteContactModal from "../../../components/InviteContactModal";
import DropDown from "../../../components/DropDown";

const Users = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const onInviteContact = (contacts) => {};

  return (
    <>
      <div className="px-4 pt-4">
        <div className="d-flex align-items-start">
          <div className="d-flex justify-content-between flex-grow-1">
            <h4 className="mb-4">Users</h4>
            <div className="flex-shrink-0">
              <div id="add-contact">
                {/* Button trigger modal */}
                <AddButton onClick={openModal} />
              </div>
              <UncontrolledTooltip target="add-contact" placement="bottom">
                Invite User
              </UncontrolledTooltip>
            </div>
          </div>
        </div>
        <Form>
          <div className="input-group mb-3">
            <Input
              // onKeyUp={searchUsers}
              id="serachChatUser"
              type="text"
              className="form-control bg-light border-0 pe-0"
              placeholder="Search here.."
            />
            <Button color="light" type="button" id="searchbtn-addon">
              <i className="bx bx-search align-middle"></i>
            </Button>
          </div>
        </Form>
        <ul style={{ listStyle: "none", padding: "0" }}>
          <li style={{ height: "50px" }}>
            <Link to="#">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center gap-3">
                  <img src={img1} className="rounded-circle avatar-xs" alt="" />

                  <div className="overflow-hidden d-flex gap-5 ">
                    <h5 className="font-size-14 mb-0">User Full Name</h5>
                    <span className="font-size-12">Admin</span>
                  </div>
                </div>

               <DropDown user="user" />
              </div>
            </Link>
          </li>
        </ul>
      </div>
      {isOpen && (
        <InviteContactModal
          isOpen={isOpen}
          onClose={closeModal}
          onInvite={onInviteContact}
        />
      )}

      {/* {isOpenAddContact && (
        <ContactModal
          isOpen={isOpenAddContact}
          onClose={closeAddContactModal}
          onAddContact={onAddContact}
        /> */}
      {/* )} */}
    </>
  );
};

export default Users;
