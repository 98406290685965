import React, { useState } from "react";
import SliderUsers from "../../../components/Slider";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { subscriptionActions } from "../../../redux/SubscriptionUserCart/SubscriptionUserCartSlice";
import AppSimpleBar from "../../../components/AppSimpleBar";
import { Row, Col, Button, Input } from "reactstrap";
import TopBarResponsive from "../../../components/TopBarResponsive";
const AdminSubscription = () => {
  const [hide, setHide] = useState(false);
  const subscriptionValue = useSelector((state) => state.subscription);

  const dispatch = useDispatch();
  const getTokensVal = (valeo) => {
    dispatch(
      subscriptionActions.changeTokens({
        val: valeo,
      })
    );
  };

  const getUsersVal = (valeo) => {
    dispatch(
      subscriptionActions.changeUsers({
        val: valeo,
      })
    );
  };

  const getDataSizeVal = (valeo) => {
    dispatch(
      subscriptionActions.changeDataSize({
        val: valeo,
      })
    );
  };
  return (
    <>
      <TopBarResponsive />
      <AppSimpleBar className="payment-screen ">
        <div className="card custom-div ">
          <div className="p-4">
            <Row>
              <Col sm={12} md={6}>
                <div className="payment-method">
                  <h4 className="mb-4">Payment Method</h4>
                  <hr />
                  <div className="d-flex align-items-center justify-content-between">
                    <h5 style={{ fontWeight: "bold" }}>Billing Contact</h5>
                    <div>
                      {!hide && (
                        <Button
                          color="none"
                          type="button"
                          className="btn btn-soft-primary btn-sm"
                          onClick={() => {
                            setHide(!hide);
                          }}
                        >
                          <i className="bx bxs-pencil align-middle"></i>
                        </Button>
                      )}
                      {hide && (
                        <Button
                          color="none"
                          type="button"
                          className="btn btn-soft-primary btn-sm"
                          onClick={() => {
                            setHide(!hide);
                          }}
                        >
                          <i className="bx bxs-save align-middle"></i>
                        </Button>
                      )}
                    </div>
                  </div>

                  <h6
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      margin: "0",
                    }}
                  >
                    Company Name:
                  </h6>
                  {!hide && (
                    <p
                      className="text-muted"
                      style={{ fontSize: "14px", marginTop: "5px" }}
                    >
                      Orthoplex
                    </p>
                  )}
                  {hide && (
                    <Input
                      type="text"
                      name="email"
                      placeholder="Enter company name"
                      className="form-control mt-2 mb-2"
                    />
                  )}

                  <h6
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      margin: "0",
                    }}
                  >
                    Contact of:
                  </h6>
                 
                  {!hide && (
                     <p
                     className="text-muted"
                     style={{ fontSize: "14px", marginTop: "5px" }}
                   >
                     Fady
                   </p>
                  )}
                  {hide && (
                    <Input
                      type="text"
                      name="email"
                      placeholder=" Contact of"
                      className="form-control mt-2 mb-2"
                    />
                  )}
              

                  <h6
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      margin: "0",
                    }}
                  >
                    Billing address :
                  </h6>
                  {!hide && (
                        <p
                        className="text-muted"
                        style={{ fontSize: "14px", marginTop: "5px" }}
                      >
                        Orthoplex Solutions 84A Abdel-Aziz Fahmi St Heliopolis,
                        Cairo Egypt
                      </p>
                  )}
                  {hide && (
                    <Input
                      type="text"
                      name="email"
                      placeholder="Enter billing address"
                      className="form-control mt-2 mb-2"
                    />
                  )}
              

                  <h6
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      margin: "0",
                    }}
                  >
                    Tax ID :
                  </h6>
            
                  {!hide && (
                         <p
                         className="text-muted"
                         style={{ fontSize: "14px", marginTop: "5px" }}
                       >
                         723429
                       </p>
                  )}
                  {hide && (
                    <Input
                      type="text"
                      name="email"
                      placeholder=" Enter Tax ID"
                      className="form-control mt-2 mb-2"
                    />
                  )}
              
                </div>
                <hr />
                <h6 style={{ fontWeight: "bold" }}>Payment Info</h6>

                <div className="d-flex">
                  <i
                    className="bx bxl-paypal "
                    style={{ fontSize: "40px", cursor: "pointer" }}
                  ></i>
                  <i
                    className="bx bxl-mastercard"
                    style={{ fontSize: "40px", cursor: "pointer" }}
                  ></i>
                </div>
              </Col>

              <Col sm={12} md={6}>
                <h4 style={{ height: "15px", paddingLeft: "20px" }}>Plan</h4>
                <div>
                  {" "}
                  <AppSimpleBar className="admin-payment-card">
                    <div
                      style={{
                        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",

                        padding: "25px",

                        borderRadius: "5px",
                        maxHeight: "fit-content",
                      }}
                    >
                      <div>
                        <div
                          className="d-flex justify-content-between align-content-center"
                          style={{
                            fontSize: "15px",
                            fontWeight: "600",
                          }}
                        >
                          <p className="text-muted" style={{ margin: "0" }}>
                            Users
                          </p>
                          <p className="text-muted" style={{ margin: "0" }}>
                            ${subscriptionValue.usersCost}
                          </p>
                        </div>

                        <SliderUsers getVal={getUsersVal} max={200} step={20} />
                        <div
                          className="d-flex gap-3 align-items-center"
                          style={{ maxHeight: "40px" }}
                        >
                          <p
                            style={{
                              backgroundColor: "#9A96FC",
                              color: "white",
                              padding: "5px 12px",
                              borderRadius: "10px",
                            }}
                          >
                            {subscriptionValue.users}
                          </p>
                          <p
                            className="text-muted"
                            style={{ fontSize: "14px", opacity: "0.9" }}
                          >
                            users
                          </p>
                        </div>
                      </div>
                      <div>
                        <hr style={{ margin: "5px" }} />
                        <div
                          className="d-flex justify-content-between align-content-center"
                          style={{
                            fontSize: "15px",
                            fontWeight: "600",
                          }}
                        >
                          <p className="text-muted" style={{ margin: "0" }}>
                            Data Size
                          </p>
                          <p className="text-muted" style={{ margin: "0" }}>
                            ${subscriptionValue.dataSizeCost}
                          </p>
                        </div>

                        <SliderUsers
                          getVal={getDataSizeVal}
                          max={10}
                          step={1}
                        />
                        <div
                          className="d-flex gap-3 align-items-center"
                          style={{ maxHeight: "40px" }}
                        >
                          <p
                            style={{
                              backgroundColor: "#9A96FC",
                              color: "white",
                              padding: "5px 12px",
                              borderRadius: "10px",
                            }}
                          >
                            {subscriptionValue.dataSize}
                          </p>
                          <p
                            className="text-muted"
                            style={{ fontSize: "14px", opacity: "0.9" }}
                          >
                            GB
                          </p>
                        </div>
                      </div>
                      <hr style={{ margin: "5px" }} />
                      <div>
                        <div
                          className="d-flex justify-content-between align-content-center"
                          style={{
                            fontSize: "15px",
                            fontWeight: "600",
                          }}
                        >
                          <p className="text-muted" style={{ margin: "0" }}>
                            Tokens
                          </p>
                          <p className="text-muted" style={{ margin: "0" }}>
                            ${subscriptionValue.tokensCost}
                          </p>
                        </div>

                        <SliderUsers
                          getVal={getTokensVal}
                          max={1000}
                          step={50}
                        />
                        <div
                          className="d-flex gap-3 align-items-center"
                          style={{ maxHeight: "40px" }}
                        >
                          <p
                            style={{
                              backgroundColor: "#9A96FC",
                              color: "white",
                              padding: "5px 12px",
                              borderRadius: "10px",
                            }}
                          >
                            {subscriptionValue.tokens}
                          </p>
                          <p
                            className="text-muted"
                            style={{ fontSize: "14px", opacity: "0.9" }}
                          >
                            Tokens
                          </p>
                        </div>
                      </div>
                    </div>
                    <h5
                      style={{
                        padding: "0",
                        paddingLeft: "20px",
                        marginTop: "20px",
                        height: "10px",
                      }}
                    >
                      Total: ${subscriptionValue.total}
                    </h5>
                  </AppSimpleBar>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </AppSimpleBar>
    </>
  );
};

export default AdminSubscription;
