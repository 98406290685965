import React, { useEffect, useState } from "react";
import { Form, Button, Modal, ModalFooter, Input } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
const AddFolderPopup = ({ isOpen, onClose }) => {
  const validation = useFormik({
    initialValues: {
      fileName: "",
    },
    enableReinitialize: false,
    validationSchema: Yup.object({
      fileName: Yup.string().required("Please Enter file name."),
    }),
    onSubmit: (values) => {
      console.log({ fileName: values.fileName });
    },
  });

  return (
    <Modal isOpen={isOpen} toggle={onClose} tabIndex={-1} centered scrollable>
      <div className="p-5">
        <h6 className="">Make New Folder</h6>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Input
            label="fileName"
            type="text"
            name="fileName"
            placeholder="Enter File Name"
            className="form-control"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.fileName || ""}
          />
          {validation.touched.fileName && validation.errors.fileName ? (
            <p style={{ color: "red" }}>{validation.errors.fileName}</p>
          ) : null}
          <ModalFooter>
            <Button
              type="button"
              color="link"
              className="btn"
              onClick={onClose}
            >
              Close
            </Button>
            <Button color="primary" type="submit">
              Add
            </Button>
          </ModalFooter>
        </Form>
      </div>
    </Modal>
  );
};

export default AddFolderPopup;
