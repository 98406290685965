import { createSlice } from "@reduxjs/toolkit";

const ThemeSlice = createSlice({
  name: "theme",
  initialState: {
    themeMod: "",
  },
  reducers: {
    changeTheme(state, action) {
      const themeSelected = action.payload.theme;
      state.themeMod = themeSelected;
    },
  },
});

export const themeActions = ThemeSlice.actions;
export default ThemeSlice;
