import React from "react";
import Conversation from "./Conversation";
import TopBarResponsive from "../../../components/TopBarResponsive";
const UserHistoryAdmin = () => {
  return (
    <>
      <TopBarResponsive />
      <Conversation />
    </>
  );
};

export default UserHistoryAdmin;
