import React from "react";
import { Input } from "reactstrap";

const SubscribtionCost = () => {
  return (
    <div className="p-4">
      <div className="d-flex justify-content-between align-items-center">
        <h6 className="m-0 w-50">Users</h6>
        <div className="d-flex justify-content-end align-items-center">
          <i className="bx bx-dollar"></i>
          <Input type="number" name="users" placeholder="" className="w-50" />
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center pt-2 pb-2">
        <h6 className="m-0 w-50">Data Size</h6>
        <div className="d-flex justify-content-end align-items-center">
          <i className="bx bx-dollar"></i>
          <Input
            type="number"
            name="dataSize"
            placeholder=""
            className="w-50"
          />
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center pt-2 pb-2">
        <h6 className="m-0 w-50">Tokens</h6>
        <div className="d-flex justify-content-end align-items-center">
          <i className="bx bx-dollar"></i>
          <Input type="number" name="tokens" placeholder="" className="w-50" />
        </div>
      </div>
      <div className="d-flex justify-content-center mt-2">
      <button type="button" className="btn btn-soft-primary btn-sm pb-0 w-75">
        <p className="m-0 p-1">Save</p>
      </button>
      </div>
    </div>
  );
};

export default SubscribtionCost;
