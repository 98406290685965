import { logDOM } from "@testing-library/react";
import React, { useState } from "react";
import {
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const DropDown = ({user}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  const [isOpen, setIsOpen] = useState(false);
 console.log("prop",user);
  return (
    <>
      {user === "user" ? (
        <Dropdown
          isOpen={dropdownOpen}
          toggle={toggle}
          className="d-flex justify-content-end "
        >
          <DropdownToggle color="none" style={{ padding: "0" }} type="button">
            <i className="bx bx-dots-vertical-rounded"></i>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem className="d-flex justify-content-between align-items-center user-profile-show">
              Approve <i className="bx bx-check text-muted"></i>
            </DropdownItem>
            <DropdownItem className="d-flex justify-content-between align-items-center">
              Remove <i className="bx bxs-trash text-muted"></i>
            </DropdownItem>
            <DropdownItem className="d-flex justify-content-between align-items-center ">
              Make admin <i className="bx bx-star text-muted"></i>
            </DropdownItem>
            <DropdownItem className="d-flex justify-content-between gap-4 align-items-center ">
              Dismiss as admin <i className="bx bx-user text-muted"></i>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      ) : (
        <Dropdown
          isOpen={dropdownOpen}
          toggle={toggle}
          className="d-flex justify-content-end "
        >
          <DropdownToggle color="none" style={{ padding: "0" }} type="button">
            <i className="bx bx-dots-vertical-rounded"></i>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem className="d-flex justify-content-between align-items-center user-profile-show">
              Share <i className="bx bx-share text-muted"></i>
            </DropdownItem>
            <DropdownItem className="d-flex justify-content-between align-items-center">
              Rename <i className="bx bxs-pencil text-muted"></i>
            </DropdownItem>
            <DropdownItem className="d-flex justify-content-between align-items-center ">
             Delete<i className="bx bx-trash text-muted"></i>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      )}
    </>
  );
};

export default DropDown;
