import "./routes/AllRoutes";
import "./assets/scss/theme.scss";
import Loading from "../src/pages/Loading";
import Routes from "./routes";
function App() {
  let isLoading = false;

  return <>{isLoading ? <Loading /> : <Routes />}</>;
}

export default App;
