import React from "react";
import classnames from "classnames";

import { useSelector } from "react-redux";
import ManageData from "./ManageData";
import ShowAnalytics from "./SuperAdmin/AnalyticsShow";
import AdminSubscription from "./Settings/AdminSubscription";
import WelcomeManagedData from "./ManageData/WelcomeManagedData";

import Leftbar from "./Leftbar";
import ConversationUser from "./ConversationUser/index";

import Welcome from "./ConversationUser/Welcome";
import WelcomeSuperAdmin from "./SuperAdmin/WelcomeSuperAdmin";
import SuperAdminUsers from "./SuperAdmin/SuperAdminUsers";
import UserHistoryAdmin from "./ConversationUser/UserHistoryAdmin";
const Index = (props) => {
  const tabSelected = useSelector((state) => state.sideBar.tab);
  const overlay = useSelector((state) => state.sideBar.overlay);
  const showUserChat = useSelector((state) => state.sideBar.showOverlay);
  const chooseBot = useSelector((state) => state.sideBar.chooseBot);
  const historyChat = useSelector((state) => state.sideBar.historyChat);


  return (
    <>
      {" "}
      <Leftbar />
      <div
        className={classnames("user-chat", "w-100", "overflow-hidden", {
          "user-chat-show": showUserChat,
        })}
        id="user-chat"
      >
        {tabSelected === "superAdmin" ? (
          <>
            {overlay === "analytics" ? (
              <div className="w-100 overflow-hidden position-relative">
                <ShowAnalytics />
              </div>
            ) : true ? (
              <div className="chat-content d-lg-flex">
                <div className="w-100 overflow-hidden position-relative">
                  <SuperAdminUsers />
                </div>
              </div>
            ) : (
              <WelcomeSuperAdmin />
            )}
          </>
        ) : tabSelected === "Manage Data" ? (
          <>
            {!chooseBot ? (
              <WelcomeManagedData />
            ) : historyChat ? (
              <UserHistoryAdmin />
            ) : (
              <div className="chat-content d-lg-flex">
                <div className="w-100 overflow-hidden position-relative">
                  <ManageData />
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {" "}
            {overlay === "adminSubscription" && tabSelected === "setting" ? (
              <div className="w-100 overflow-hidden position-relative">
                <AdminSubscription />
              </div>
            ) : true ? (
              <div className="chat-content d-lg-flex">
                <div className="w-100 overflow-hidden position-relative">
                  <ConversationUser />
                </div>
              </div>
            ) : (
              <Welcome />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Index;
