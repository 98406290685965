import Login from "../pages/Auth/Login";
import Logout from "../pages/Auth/Logout";
import RecoverPassword from "../pages/Auth/RecoverPassword";
import ChangePassword from "../pages/Auth/ChangePassword";

import Dashboard from "../pages/Dashbord/index";

import StarterPage from "../pages/StarterPage/index.";
import ErrorPage from "../pages/Auth/ErrorPage/ErrorPage";

import Register from "../pages/Auth/Register";
import Root from "../pages/Root";
const publicRoutes = [
  { path: "/*", component: <ErrorPage /> },   
  { path: "/pages-starter", component: <StarterPage /> },
  { path: "/auth-login", component: <Login /> },
  { path: "/auth-register", component: <Register /> },
  { path: "/auth-recoverpw", component: <RecoverPassword /> },
  { path: "/auth-changepassword", component: <ChangePassword /> },
  // { path: "/auth-lock-screen", component: <LockScreen /> },
  { path: "/logout", component: <Logout /> },
];

const privateRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/", component: <Root /> },
];

export { publicRoutes, privateRoutes };
