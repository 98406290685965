import React, { useState } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import SliderUsers from "../../../components/Slider";
import { InputText } from "primereact/inputtext";
import { Form, Button, UncontrolledTooltip } from "reactstrap";
import AppSimpleBar from "../../../components/AppSimpleBar";
import { Row, Col } from "reactstrap";
import { Dropdown } from "primereact/dropdown";
const Settings = () => {
  const [temperature, setTemperature] = useState(0);
  const [selectedCity, setSelectedCity] = useState(null);
  const cities = [
    { name: "ChatGPT 3.5", code: "NY" },
    { name: "ChatGPT 4", code: "RM" },
  ];
  let data =
    "I want you to act as a document that I am having a conversation with. Your name is AI Assistant. You will provide me with answers from the given info. If the answer is not included, say exactly , I am not sure. and stop after that. Refuse to answer any question not about the info. Never break character.";

  const getTemperatureVal = (val) => {
    setTemperature(val);
  };

  return (
    <>
      <AppSimpleBar className="admin-payment-card">
        <div className="p-3">
          <div className="d-flex justify-content-end ">
            <Button
              color="primary"
              type="submit"
              style={{ width: "70px", height: "30px", padding: "0px" }}
            >
              Reset
            </Button>
          </div>
          <Form>
            <Row className="d-flex justify-content-center">
              <Col sm={12} xxl={6}>
                {" "}
                <div className="mb-5" style={{ marginBottom: " !important" }}>
                  <h4>Base Prompt (system message)</h4>
                  <InputTextarea
                    placeholder={data}
                    rows={5}
                    cols={30}
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="mb-5">
                  <div className="d-flex justify-content-between align-items-center">
                    <h4>Temperature</h4>
                    <h6>{temperature}</h6>
                  </div>

                  <div style={{ width: "98%", margin: "0 auto" }}>
                    <SliderUsers
                      getVal={getTemperatureVal}
                      max={1}
                      step={0.1}
                    />
                    <div className="d-flex justify-content-between font-size-12">
                      <p>Reserved</p>
                      <p>Creative</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={12} xxl={6}>
                <div className="mb-5">
                  <h4>
                    Rate Limiting <i id="info" class="bx bx-info-circle"></i>
                  </h4>

                  <UncontrolledTooltip target={"info"} placement="right">
                    Limit the number of messages sent from one device on the
                    iframe and chat bubble (this limit will not be applied to
                    you on chatbase.co, only on your website for your users to
                    prevent abuse)
                  </UncontrolledTooltip>
                  <div className="d-flex gap-1 flex-column w-100">
                    <div className="d-flex align-items-center gap-3 flex-wrap">
                      <h5 className="p-0 m-0 m-0"># of messages </h5>{" "}
                      <InputText
                        type="number"
                        className="p-inputtext-sm w-100"
                        placeholder="0"
                      />
                    </div>
                    <div className="d-flex align-items-center gap-3 flex-wrap w-100">
                      <h5 className="p-0 mt-2">Rate L. Reset (s)</h5>
                      <InputText
                        type="number"
                        className="p-inputtext-sm w-100"
                        placeholder="0"
                      />
                    </div>
                  </div>
                  <h5 className="p-0 mt-3 mb-1">Engine</h5>
                  <div className="card flex justify-content-center">
                    <h5 className="p-0 mt-2"> select version</h5>
                    <Dropdown
                      value={selectedCity}
                      onChange={(e) => setSelectedCity(e.value)}
                      options={cities}
                      optionLabel="name"
                      placeholder="Select a City"
                      className="w-full md:w-14rem"
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <Button color="primary" type="submit">
                      Update
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </AppSimpleBar>
    </>
  );
};

export default Settings;
