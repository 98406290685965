import React, { useEffect, useRef, useCallback, useState } from "react";
import AppSimpleBar from "../../../components/AppSimpleBar";
import Loader from "../../../components/Loader";
import Message from "./Message";
import ForwardModal from "../../../components/ForwardModal";

const Conversation = ({
  // chatUserDetails,
  // chatUserConversations,
  onDelete,
  onSetReplyData,
  isChannel,
}) => {
  const ref = useRef();
  const scrollElement = useCallback(() => {
    if (ref && ref.current) {
      const listEle = document.getElementById("chat-conversation-list");
      let offsetHeight = 520;
      if (listEle) {
        offsetHeight = listEle.scrollHeight - window.innerHeight + 250;
      }
      if (offsetHeight) {
        ref.current
          .getScrollElement()
          .scrollTo({ top: offsetHeight, behavior: "smooth" });
      }
    }
  }, [ref]);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.recalculate();
      scrollElement(); // Scroll to the last message
    }
  }, [scrollElement]);

  const [forwardData, setForwardData] = useState();
  const [isOpenForward, setIsOpenForward] = useState(false);
  const onOpenForward = (message) => {
    setForwardData(message);
    setIsOpenForward(true);
  };
  const onCloseForward = () => {
    setIsOpenForward(false);
  };

  const onForwardMessage = (data) => {
    const params = {
      contacts: data.contacts,
      message: data.message,
      forwardedMessage: forwardData,
    };
  };

  const onDeleteImage = () => {};
  let myId = 123239;

  let conversations = [
    {
      conversationId: 1,
      userId: "614ecab4ac946a9bdafa4e3b",
      typingUser: "614ecab4ac946a9bdafa4e3b",
      messages: [
        {
          mId: 1,
          text: "Good morning 😊",
          time: new Date().toISOString(),
          meta: {
            receiver: myId,
            sender: "614ecab4ac946a9bdafa4e3b",
            sent: true,
            received: true,
            read: true,
          },
        },
        {
          mId: 2,
          text: "Good morning, How are you? What about our next meeting?",
          time: new Date().toISOString(),
          meta: {
            receiver: "614ecab4ac946a9bdafa4e3b",
            sender: myId,
            sent: true,
            received: true,
            read: true,
          },
        },
        {
          mId: 3,
          text: "Yeah everything is fine. Our next meeting tomorrow at 10.00 AM",
          time: new Date().toISOString(),
          meta: {
            receiver: myId,
            sender: "614ecab4ac946a9bdafa4e3b",
            sent: true,
            received: true,
            read: true,
          },
        },
        {
          mId: 4,
          text: "Hey, I'm going to meet a friend of mine at the department store. I have to buy some presents for my parents 🎁",
          time: new Date().toISOString(),
          meta: {
            receiver: myId,
            sender: "614ecab4ac946a9bdafa4e3b",
            sent: true,
            received: true,
            read: true,
          },
        },
        {
          mId: 5,
          text: "Wow that's great",
          time: new Date().toISOString(),
          meta: {
            receiver: "614ecab4ac946a9bdafa4e3b",
            sender: myId,
            sent: true,
            received: true,
            read: true,
          },
        },
        {
          mId: 6,
          text: "Thank you",
          time: new Date().toISOString(),
          meta: {
            receiver: myId,
            sender: "614ecab4ac946a9bdafa4e3b",
            sent: true,
            received: true,
            read: true,
          },
          image: [
            {
              id: 1,
              downloadLink: "img1",
            },
            {
              id: 2,
              downloadLink: "img2",
            },
          ],
        },
        {
          mId: 7,
          text: "You are welcome",
          time: new Date().toISOString(),
          meta: {
            receiver: "614ecab4ac946a9bdafa4e3b",
            sender: myId,
            sent: true,
            received: true,
            read: true,
          },
          attachments: [
            {
              id: 1,
              name: "design-phase-1-approved.pdf",
              downloadLink: "",
              desc: "12.5 MB",
            },
          ],
        },
      ],
    },
  ];
  return (
    <AppSimpleBar
      scrollRef={ref}
      className="chat-conversation p-3 p-lg-4 positin-relative"
    >
      <ul
        className="list-unstyled chat-conversation-list"
        id="chat-conversation-list"
      >
        {conversations.map((message, key) => {
          const msg = message?.messages?.map((msgs, idx) => {
            let isFromMe;
            if (msgs.meta.sender === myId) {
              isFromMe = true;
            } else {
              isFromMe = false;
            }

            return (
              <Message
                message={msgs.text}
                key={idx}
                // chatUserDetails={chatUserDetails}
                onDelete={onDelete}
                onSetReplyData={onSetReplyData}
                isFromMe={isFromMe}
                onOpenForward={onOpenForward}
                isChannel={isChannel}
                onDeleteImage={onDeleteImage}
              />
            );
          });

          return msg;
        })}
      </ul>
      {isOpenForward && (
        <ForwardModal
          isOpen={isOpenForward}
          onClose={onCloseForward}
          forwardData={forwardData}
          onForward={onForwardMessage}
        />
      )}
    </AppSimpleBar>
  );
};

export default Conversation;
