import React from "react";
import { TabPane } from "reactstrap";
import { useSelector } from "react-redux/es/exports";
import Chats from "./Chats/index";
import Settings from "./Settings/index";
import Users from "./Users";
import BotSideBar from "./ManageData/BotSideBar";
import SuperAdmin from "./SuperAdmin";

const Leftbar = () => {
  const tabSelected = useSelector((state) => state.sideBar.tab);

  return (
    <div className="fixed-z">
      <div className="chat-leftsidebar">
        {tabSelected === "chat" && (
          <TabPane role="tabpanel" className="active">
            <Chats />
          </TabPane>
        )}

        {tabSelected === "setting" && (
          <TabPane role="tabpanel" className="active">
            <Settings />
          </TabPane>
        )}

        {tabSelected === "users" && (
          <TabPane role="tabpanel" className="active">
            <Users />
          </TabPane>
        )}

        {tabSelected === "Manage Data" && (
          <TabPane role="tabpanel" className="active">
            <BotSideBar />
          </TabPane>
        )}

        {tabSelected === "superAdmin" && (
          <TabPane role="tabpanel" className="active">
            <SuperAdmin />
          </TabPane>
        )}
      </div>
    </div>
  );
};

export default Leftbar;
