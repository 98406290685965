import React, { useEffect } from "react";
import NonAuthLayoutWrapper from "../../components/NonAutnLayoutWrapper";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import setTitle from "../../hooks/Title";

const StarterPage = () => {
  useEffect(() => {
    setTitle(" ");
  }, []);
  return (
    <NonAuthLayoutWrapper>
      <Row className="justify-content-center my-auto">
        <Col sm={8} lg={6} xl={5} className="col-xxl-4">
          <div className="py-md-5 py-4 text-center">
            <div className="avatar-xl mx-auto mb-4">
              <div className="avatar-title bg-soft-primary rounded-circle">
                <i className="bx bxs-message-alt-detail display-4 text-primary m-0"></i>
              </div>
            </div>
            <div className="mt-4 pt-2">
              <h3>Welcome to Beky.ai Chat App</h3>
              <div className="mt-4">
                <Link
                  to="/auth-login"
                  className="btn btn-primary w-100 waves-effect waves-light"
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </NonAuthLayoutWrapper>
  );
};

export default StarterPage;
