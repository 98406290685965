import React from 'react'

const CardIcon = ({children}) => {
  return (
    <> 
    <div className="card-icon icon-card">
        {children}
    </div>

    </>
  )
}

export default CardIcon