import React ,{ useState } from "react";
import { Button, Input } from "reactstrap";


const PersonalInfo = () => {
  // const fullName = basicDetails
  //   ? `${basicDetails.firstName} ${basicDetails.lastName}`
  const [hide, setHide] = useState(false);

  //   : "-";
  return (
    <div className="accordion-body">
      <div className="float-end mb-3">
        {!hide && (
          <Button
            color="none"
            type="button"
            className="btn btn-soft-primary btn-sm"
            onClick={() => {
              setHide(!hide);
            }}
          >
            <i className="bx bxs-pencil align-middle"></i>
          </Button>
        )}
        {hide && (
          <Button
            color="none"
            type="button"
            className="btn btn-soft-primary btn-sm"
            onClick={() => {
              setHide(!hide);
            }}
          >
            <i className="bx bxs-save align-middle"></i>
          </Button>
        )}
      </div>

      <div>
        <p className="text-muted mb-1">Name</p>

        {!hide && <h5 className="font-size-14">USer Full Name</h5>}
        {hide && (
          <Input
            type="text"
            placeholder="Enter your full user name"
            className="form-control"
          />
        )}
      </div>

      <div className="mt-4">
        <p className="text-muted mb-1">Email</p>
        <h5 className="font-size-14">adc@123.com</h5>
      </div>
    </div>
  );
};

export default PersonalInfo;
