import React from "react";

import { Button, UncontrolledPopover, PopoverBody } from "reactstrap";

const EndButtons = ({ onSubmit, disabled }) => {
  return (
    <div className="chat-input-links ms-2 gap-md-1">
      <div className="links-list-item">
        <Button
          color="primary"
          type="submit"
          disabled={disabled}
          className="btn btn-primary btn-lg chat-send waves-effect waves-light"
        >
          <i className="bx bxs-send align-middle"></i>
        </Button>
      </div>
    </div>
  );
};

export default EndButtons;
