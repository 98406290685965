import UploadPopup from "../../../components/UploadPopup";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import UploadFile from "./FileUploader";
import AppSimpleBar from "../../../components/AppSimpleBar";
import { UncontrolledTooltip } from "reactstrap";
import React, { useState } from "react";
import AddFolderPopup from "../../../components/AddFolderPopup";
const ManageData = () => {
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const [addFolder, setAddFolder] = useState(false);

  const closeFolderModal = () => {
    setAddFolder(false);
  };
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <>
      {isOpen && (
        <UploadPopup
          isOpen={isOpen}
          onClose={closeModal}
          // onInvite={onInviteContact}
        />
      )}
      <div className="">
        <div className=" p-4  pt-0">
          <div className="d-flex align-items-center">
            <div className="flex-grow-1 d-flex justify-content-between align-items-center mt-3">
              <h4 className="mb-0 font-size-16 text-muted text-uppercase">
                Uploaded Data
              </h4>

              <div className="d-flex gap-3">
                <button type="button" className="btn btn-soft-primary btn-sm ">
                  <p className="m-0">Retrain</p>
                </button>

                <button
                  type="button"
                  className="btn btn-soft-primary btn-sm "
                  onClick={openModal}
                  id="uploadData"
                >
                  <i className="bx bx-upload"></i>
                  <UncontrolledTooltip target={"uploadData"} placement="bottom">
                    Upload Data
                  </UncontrolledTooltip>
                </button>
                <button
                  type="button"
                  className="btn btn-soft-primary btn-sm"
                  onClick={() => {
                    setAddFolder(!addFolder);
                  }}
                  id="uploadData"
                >
                  <i className="bx bx-plus"></i>
                  <UncontrolledTooltip target={"uploadData"} placement="bottom">
                    Add folder
                  </UncontrolledTooltip>
                </button>
              </div>
            </div>
          </div>

          {/* {addFolder && (
            <div className="d-flex justify-content-center ">
              <Form className="d-flex w-50 ">
                <Input
                  type="text"
                  name="filename"
                  placeholder="Enter folder name"
                  className="form-control"
                />
                <Button className="btn btn-primary" type="submit">
                  add
                </Button>
              </Form>
            </div>
          )} */}

          {addFolder && (
            <AddFolderPopup
              isOpen={addFolder}
              onClose={closeFolderModal}
              // onInvite={onInviteContact}
            />
          )}
          <div className="flex-grow-1 mt-3"></div>
          <AppSimpleBar className="user-setting">
            <div className="mt-3  ">
              <Accordion open={open} toggle={toggle}>
                <AccordionItem>
                  <AccordionHeader targetId="1">First learn</AccordionHeader>
                  <AccordionBody accordionId="1">
                    <ul style={{ listStyle: "none" }}>
                      <li style={{ height: "60px" }}>
                        <div className="d-flex justify-content-between align-contents-center">
                          <div className="d-flex gap-3 align-items-center">
                            <i
                              id="notTrained"
                              className={` ${
                                false ? "bx bxs-bot" : "bx bx-bot"
                              }`}
                            ></i>
                            <span> Data 1</span>
                            <UncontrolledTooltip
                              target="notTrained"
                              placement="bottom"
                            >
                              Not Trained
                            </UncontrolledTooltip>
                          </div>
                          <div className="d-flex gap-3 align-items-center">
                            <span className="font-size-11">130 KB</span>
                            <i
                              id="download"
                              className="bx bxs-download text-muted"
                            ></i>
                            <UncontrolledTooltip
                              target="download"
                              placement="bottom"
                            >
                              Download
                            </UncontrolledTooltip>
                            <i
                              id="trash"
                              className="bx bxs-trash text-muted"
                            ></i>
                            <UncontrolledTooltip
                              target="trash"
                              placement="bottom"
                            >
                              Remove
                            </UncontrolledTooltip>
                          </div>
                        </div>

                        <span
                          className="font-size-12"
                          style={{ marginLeft: "32px" }}
                        >
                          By Fatma on 22/5/2023
                        </span>
                      </li>
                      <li style={{ height: "60px" }}>
                        <div className="d-flex justify-content-between align-contents-center">
                          <div className="d-flex gap-3 align-items-center">
                            <i
                              id="trained"
                              className={` ${
                                true ? "bx bxs-bot" : "bx bx-bot"
                              }`}
                            ></i>
                            <span> Data 2</span>
                            <UncontrolledTooltip
                              target="trained"
                              placement="bottom"
                            >
                              Trained
                            </UncontrolledTooltip>
                          </div>
                          <div className="d-flex gap-3 align-items-center">
                            <span className="font-size-11">130 KB</span>
                            <i
                              id="download"
                              className="bx bxs-download text-muted"
                            ></i>
                            <UncontrolledTooltip
                              target="download"
                              placement="bottom"
                            >
                              Download
                            </UncontrolledTooltip>
                            <i
                              id="trash"
                              className="bx bxs-trash text-muted"
                            ></i>
                            <UncontrolledTooltip
                              target="trash"
                              placement="bottom"
                            >
                              Remove
                            </UncontrolledTooltip>
                          </div>
                        </div>

                        <span
                          className="font-size-12"
                          style={{ marginLeft: "32px" }}
                        >
                          By Fatma on 22/5/2023
                        </span>
                      </li>
                      <li style={{ height: "60px" }}>
                        <div className="d-flex justify-content-between align-contents-center">
                          <div className="d-flex gap-3 align-items-center">
                            <i
                              id="notTrained"
                              className={` ${
                                false ? "bx bxs-bot" : "bx bx-bot"
                              }`}
                            ></i>
                            <span> Data 1</span>
                            <UncontrolledTooltip
                              target="notTrained"
                              placement="bottom"
                            >
                              Not Trained
                            </UncontrolledTooltip>
                          </div>
                          <div className="d-flex gap-3 align-items-center">
                            <span className="font-size-11">130 KB</span>
                            <i
                              id="download"
                              className="bx bxs-download text-muted"
                            ></i>
                            <UncontrolledTooltip
                              target="download"
                              placement="bottom"
                            >
                              Download
                            </UncontrolledTooltip>
                            <i
                              id="trash"
                              className="bx bxs-trash text-muted"
                            ></i>
                            <UncontrolledTooltip
                              target="trash"
                              placement="bottom"
                            >
                              Remove
                            </UncontrolledTooltip>
                          </div>
                        </div>

                        <span
                          className="font-size-12"
                          style={{ marginLeft: "32px" }}
                        >
                          By Fatma on 22/5/2023
                        </span>
                      </li>
                    </ul>
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="2">First learn</AccordionHeader>
                  <AccordionBody accordionId="2">
                    <ul style={{ listStyle: "none" }}>
                      <li style={{ height: "60px" }}>
                        <div className="d-flex justify-content-between align-contents-center">
                          <div className="d-flex gap-3 align-items-center">
                            <i
                              id="notTrained"
                              className={` ${
                                false ? "bx bxs-bot" : "bx bx-bot"
                              }`}
                            ></i>
                            <span> Data 1</span>
                            <UncontrolledTooltip
                              target="notTrained"
                              placement="bottom"
                            >
                              Not Trained
                            </UncontrolledTooltip>
                          </div>
                          <div className="d-flex gap-3 align-items-center">
                            <span className="font-size-11">130 KB</span>
                            <i
                              id="download"
                              className="bx bxs-download text-muted"
                            ></i>
                            <UncontrolledTooltip
                              target="download"
                              placement="bottom"
                            >
                              Download
                            </UncontrolledTooltip>
                            <i
                              id="trash"
                              className="bx bxs-trash text-muted"
                            ></i>
                            <UncontrolledTooltip
                              target="trash"
                              placement="bottom"
                            >
                              Remove
                            </UncontrolledTooltip>
                          </div>
                        </div>

                        <span
                          className="font-size-12"
                          style={{ marginLeft: "32px" }}
                        >
                          By Fatma on 22/5/2023
                        </span>
                      </li>
                      <li style={{ height: "60px" }}>
                        <div className="d-flex justify-content-between align-contents-center">
                          <div className="d-flex gap-3 align-items-center">
                            <i
                              id="trained"
                              className={` ${
                                true ? "bx bxs-bot" : "bx bx-bot"
                              }`}
                            ></i>
                            <span> Data 2</span>
                            <UncontrolledTooltip
                              target="trained"
                              placement="bottom"
                            >
                              Trained
                            </UncontrolledTooltip>
                          </div>
                          <div className="d-flex gap-3 align-items-center">
                            <span className="font-size-11">130 KB</span>
                            <i
                              id="download"
                              className="bx bxs-download text-muted"
                            ></i>
                            <UncontrolledTooltip
                              target="download"
                              placement="bottom"
                            >
                              Download
                            </UncontrolledTooltip>
                            <i
                              id="trash"
                              className="bx bxs-trash text-muted"
                            ></i>
                            <UncontrolledTooltip
                              target="trash"
                              placement="bottom"
                            >
                              Remove
                            </UncontrolledTooltip>
                          </div>
                        </div>

                        <span
                          className="font-size-12"
                          style={{ marginLeft: "32px" }}
                        >
                          By Fatma on 22/5/2023
                        </span>
                      </li>
                      <li style={{ height: "60px" }}>
                        <div className="d-flex justify-content-between align-contents-center">
                          <div className="d-flex gap-3 align-items-center">
                            <i
                              id="notTrained"
                              className={` ${
                                false ? "bx bxs-bot" : "bx bx-bot"
                              }`}
                            ></i>
                            <span> Data 1</span>
                            <UncontrolledTooltip
                              target="notTrained"
                              placement="bottom"
                            >
                              Not Trained
                            </UncontrolledTooltip>
                          </div>
                          <div className="d-flex gap-3 align-items-center">
                            <span className="font-size-11">130 KB</span>
                            <i
                              id="download"
                              className="bx bxs-download text-muted"
                            ></i>
                            <UncontrolledTooltip
                              target="download"
                              placement="bottom"
                            >
                              Download
                            </UncontrolledTooltip>
                            <i
                              id="trash"
                              className="bx bxs-trash text-muted"
                            ></i>
                            <UncontrolledTooltip
                              target="trash"
                              placement="bottom"
                            >
                              Remove
                            </UncontrolledTooltip>
                          </div>
                        </div>

                        <span
                          className="font-size-12"
                          style={{ marginLeft: "32px" }}
                        >
                          By Fatma on 22/5/2023
                        </span>
                      </li>
                    </ul>
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="3">First learn</AccordionHeader>
                  <AccordionBody accordionId="3">
                    <ul style={{ listStyle: "none" }}>
                      <li style={{ height: "60px" }}>
                        <div className="d-flex justify-content-between align-contents-center">
                          <div className="d-flex gap-3 align-items-center">
                            <i
                              id="notTrained"
                              className={` ${
                                false ? "bx bxs-bot" : "bx bx-bot"
                              }`}
                            ></i>
                            <span> Data 1</span>
                            <UncontrolledTooltip
                              target="notTrained"
                              placement="bottom"
                            >
                              Not Trained
                            </UncontrolledTooltip>
                          </div>
                          <div className="d-flex gap-3 align-items-center">
                            <span className="font-size-11">130 KB</span>
                            <i
                              id="download"
                              className="bx bxs-download text-muted"
                            ></i>
                            <UncontrolledTooltip
                              target="download"
                              placement="bottom"
                            >
                              Download
                            </UncontrolledTooltip>
                            <i
                              id="trash"
                              className="bx bxs-trash text-muted"
                            ></i>
                            <UncontrolledTooltip
                              target="trash"
                              placement="bottom"
                            >
                              Remove
                            </UncontrolledTooltip>
                          </div>
                        </div>

                        <span
                          className="font-size-12"
                          style={{ marginLeft: "32px" }}
                        >
                          By Fatma on 22/5/2023
                        </span>
                      </li>
                      <li style={{ height: "60px" }}>
                        <div className="d-flex justify-content-between align-contents-center">
                          <div className="d-flex gap-3 align-items-center">
                            <i
                              id="trained"
                              className={` ${
                                true ? "bx bxs-bot" : "bx bx-bot"
                              }`}
                            ></i>
                            <span> Data 2</span>
                            <UncontrolledTooltip
                              target="trained"
                              placement="bottom"
                            >
                              Trained
                            </UncontrolledTooltip>
                          </div>
                          <div className="d-flex gap-3 align-items-center">
                            <span className="font-size-11">130 KB</span>
                            <i
                              id="download"
                              className="bx bxs-download text-muted"
                            ></i>
                            <UncontrolledTooltip
                              target="download"
                              placement="bottom"
                            >
                              Download
                            </UncontrolledTooltip>
                            <i
                              id="trash"
                              className="bx bxs-trash text-muted"
                            ></i>
                            <UncontrolledTooltip
                              target="trash"
                              placement="bottom"
                            >
                              Remove
                            </UncontrolledTooltip>
                          </div>
                        </div>

                        <span
                          className="font-size-12"
                          style={{ marginLeft: "32px" }}
                        >
                          By Fatma on 22/5/2023
                        </span>
                      </li>
                      <li style={{ height: "60px" }}>
                        <div className="d-flex justify-content-between align-contents-center">
                          <div className="d-flex gap-3 align-items-center">
                            <i
                              id="notTrained"
                              className={` ${
                                false ? "bx bxs-bot" : "bx bx-bot"
                              }`}
                            ></i>
                            <span> Data 1</span>
                            <UncontrolledTooltip
                              target="notTrained"
                              placement="bottom"
                            >
                              Not Trained
                            </UncontrolledTooltip>
                          </div>
                          <div className="d-flex gap-3 align-items-center">
                            <span className="font-size-11">130 KB</span>
                            <i
                              id="download"
                              className="bx bxs-download text-muted"
                            ></i>
                            <UncontrolledTooltip
                              target="download"
                              placement="bottom"
                            >
                              Download
                            </UncontrolledTooltip>
                            <i
                              id="trash"
                              className="bx bxs-trash text-muted"
                            ></i>
                            <UncontrolledTooltip
                              target="trash"
                              placement="bottom"
                            >
                              Remove
                            </UncontrolledTooltip>
                          </div>
                        </div>

                        <span
                          className="font-size-12"
                          style={{ marginLeft: "32px" }}
                        >
                          By Fatma on 22/5/2023
                        </span>
                      </li>
                    </ul>
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
            </div>
          </AppSimpleBar>
        </div>
      </div>
    </>
  );
};

export default ManageData;
