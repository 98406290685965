import React, { useState } from "react";
import { UncontrolledTooltip } from "reactstrap";
import AddButton from "../../../components/AddButton";
import ChatChannel from "./ChatChannel";
import { useDispatch } from "react-redux";
import { sideBarActions } from "../../../redux/sideBar/sideBarSlice";
const Chanels = ({
  channels,
  openCreateChannel,
  selectedChat,
  onSelectChat,
}) => {
  const [show, setShows] = useState(false);
  const dispatch = useDispatch();

  return (
    <>
      <div className="d-flex align-items-center px-4 mt-2 mb-2">
        <div className="flex-grow-1">
          <h4 className="mb-0 font-size-11 text-muted text-uppercase">
            Active Chats
          </h4>
        </div>
        {/* <div className="flex-shrink-0">
          <div id="create-group">
            <AddButton onClick={openCreateChannel} />{" "}
          </div>
          <UncontrolledTooltip target="create-group" placement="bottom">
            Create group
          </UncontrolledTooltip>
        </div> */}
      </div>
      <div className="chat-message-list">
        <ul className="list-unstyled chat-list chat-user-list mb-3">
          {/* {(channels || []).map((channel, key) => (
            <ChatChannel
              channel={channel}
              key={key}
              // selectedChat={selectedChat}
              onSelectChat={onSelectChat}
            />
          ))} */}
          <li className="px-4">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 avatar-xs me-2">
                <span className="avatar-title rounded-circle bg-soft-light text-dark">
                  <i className="bx bx-comment"></i>
                </span>
              </div>
              <div className="flex-grow-1 overflow-hidden">
                <p
                  className="text-truncate mb-0"
                  onClick={() => {
                    dispatch(sideBarActions.showOverlay());
                  }}
                >
                  hello{" "}
                </p>
              </div>
            </div>
          </li>
          <li className="px-4">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 avatar-xs me-2">
                <span className="avatar-title rounded-circle bg-soft-light text-dark">
                  <i className="bx bx-comment"></i>
                </span>
              </div>
              <div className="flex-grow-1 overflow-hidden">
                <p className="text-truncate mb-0">hello </p>
              </div>
            </div>
          </li>
          <li className="px-4">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 avatar-xs me-2">
                <span className="avatar-title rounded-circle bg-soft-light text-dark">
                  <i className="bx bx-comment"></i>
                </span>
              </div>
              <div className="flex-grow-1 overflow-hidden">
                <p className="text-truncate mb-0">hello </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <a
        className="mb-3 px-4 mt-4 font-size-11 text-primary d-flex justify-content-center align-items-center gap-2"
        onClick={() => {
          setShows(!show);
        }}
      >
        Archived Chats <i className="bx bxs-archive-in align-middle"></i>
      </a>

      {show && (
        <>
          <div className="d-flex align-items-center px-4 mt-2 mb-2">
            <div className="flex-grow-1">
              <h4 className="mb-0 font-size-11 text-muted text-uppercase">
                Archived Chats
              </h4>
            </div>
          </div>
          <div className="chat-message-list">
            <ul className="list-unstyled chat-list chat-user-list mb-3">
              {/* {(channels || []).map((channel, key) => (
            <ChatChannel
              channel={channel}
              key={key}
              // selectedChat={selectedChat}
              onSelectChat={onSelectChat}
            />
          ))} */}
              <li className="px-4">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 avatar-xs me-2">
                    <span className="avatar-title rounded-circle bg-soft-light text-dark">
                      <i className="bx bx-comment"></i>
                    </span>
                  </div>
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-truncate mb-0">hello </p>
                  </div>
                </div>
              </li>
              <li className="px-4">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 avatar-xs me-2">
                    <span className="avatar-title rounded-circle bg-soft-light text-dark">
                      <i className="bx bx-comment"></i>
                    </span>
                  </div>
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-truncate mb-0">hello </p>
                  </div>
                </div>
              </li>
              <li className="px-4">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 avatar-xs me-2">
                    <span className="avatar-title rounded-circle bg-soft-light text-dark">
                      <i className="bx bx-comment"></i>
                    </span>
                  </div>
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-truncate mb-0">hello </p>
                  </div>
                </div>
              </li>
              <li className="px-4">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 avatar-xs me-2">
                    <span className="avatar-title rounded-circle bg-soft-light text-dark">
                      <i className="bx bx-comment"></i>
                    </span>
                  </div>
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-truncate mb-0">hello </p>
                  </div>
                </div>
              </li>
              <li className="px-4">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 avatar-xs me-2">
                    <span className="avatar-title rounded-circle bg-soft-light text-dark">
                      <i className="bx bx-comment"></i>
                    </span>
                  </div>
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-truncate mb-0">hello </p>
                  </div>
                </div>
              </li>
              <li className="px-4">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 avatar-xs me-2">
                    <span className="avatar-title rounded-circle bg-soft-light text-dark">
                      <i className="bx bx-comment"></i>
                    </span>
                  </div>
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-truncate mb-0">hello </p>
                  </div>
                </div>
              </li>
              <li className="px-4">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 avatar-xs me-2">
                    <span className="avatar-title rounded-circle bg-soft-light text-dark">
                      <i className="bx bx-comment"></i>
                    </span>
                  </div>
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-truncate mb-0">hello </p>
                  </div>
                </div>
              </li>
              <li className="px-4">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 avatar-xs me-2">
                    <span className="avatar-title rounded-circle bg-soft-light text-dark">
                      <i className="bx bx-comment"></i>
                    </span>
                  </div>
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-truncate mb-0">hello </p>
                  </div>
                </div>
              </li>
              <li className="px-4">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 avatar-xs me-2">
                    <span className="avatar-title rounded-circle bg-soft-light text-dark">
                      <i className="bx bx-comment"></i>
                    </span>
                  </div>
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-truncate mb-0">hello </p>
                  </div>
                </div>
              </li>
              <li className="px-4">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 avatar-xs me-2">
                    <span className="avatar-title rounded-circle bg-soft-light text-dark">
                      <i className="bx bx-comment"></i>
                    </span>
                  </div>
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-truncate mb-0">hello </p>
                  </div>
                </div>
              </li>
              <li className="px-4">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 avatar-xs me-2">
                    <span className="avatar-title rounded-circle bg-soft-light text-dark">
                      <i className="bx bx-comment"></i>
                    </span>
                  </div>
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-truncate mb-0">hello </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </>
      )}
    </>
  );
};

export default Chanels;
