import React, { useState } from "react";

import {
  Label,
  Input,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import img1 from "../../../assets/images/users/avatar-2.jpg";

const UserView = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);
  return (
    <div className=" p-2 p-lg-3 border-bottom ">
      <Dropdown isOpen={dropdownOpen} toggle={toggle} className="d-flex justify-content-end mt-2">
        <DropdownToggle color="none" style={{ padding: "0" }} type="button">
          <i className="bx bx-dots-vertical-rounded"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem className="d-flex justify-content-between align-items-center user-profile-show">
            Approve <i className="bx bx-check text-muted"></i>
          </DropdownItem>
          <DropdownItem className="d-flex justify-content-between align-items-center">
            Remove <i className="bx bxs-trash text-muted"></i>
          </DropdownItem>
          <DropdownItem className="d-flex justify-content-between align-items-center ">
            Make admin <i className="bx bx-star text-muted"></i>
          </DropdownItem>
          <DropdownItem className="d-flex justify-content-between gap-4 align-items-center ">
            Dismiss as admin <i className="bx bx-user text-muted"></i>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <div className="text-center pt-3">
        <div className="mb-3  profile-user">
          <img
            src={img1}
            className="rounded-circle avatar-lg img-thumbnail user-profile-image"
            alt="user-profile"
          />
          <div className="avatar-xs p-0 rounded-circle profile-photo-edit"></div>
        </div>
        <h5 className="font-size-16 mb-1 text-truncate">User Name</h5>
        <p className="font-size-16 mb-1 text-truncate">Admin</p>
      </div>
      <div>
        <h6 className=" mb-2 text-truncate">Full Name: </h6>
        <Input
          type="text"
          name="email"
          placeholder="Fady Salib"
          className="form-control my-2"
        />
        <h6 className="mb-2 text-truncate">Email: </h6>
        <Input
          type="text"
          name="email"
          placeholder="fady.salib@gmail.com"
          className="form-control my-2"
        />
      </div>
    </div>
  );
};

export default UserView;
