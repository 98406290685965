import React from "react";
import { Button, Input } from "reactstrap";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { sideBarActions } from "../../../redux/sideBar/sideBarSlice";
const Account = () => {
  const [hide, setHide] = useState(false);

  const dispatch = useDispatch();
  return (
    <div className="accordion-body">
      <div className="float-end mb-3">
        <Button
          color="none"
          type="button"
          className="btn btn-soft-primary btn-sm"
          onClick={() => {
            setHide(!hide);
            dispatch(
              sideBarActions.changeOverlay({
                overlay: "adminSubscription",
              })
            );
            dispatch(sideBarActions.showOverlay());
          }}
        >
          <i className="bx bxs-pencil align-middle"></i>
        </Button>
      </div>

      <div>
        <p className="text-muted mb-1">Subscription</p>

        <h5 className="font-size-14">Free Plan</h5>
      </div>

      <div className="mt-4">
        <p className="text-muted mb-1">Payment method</p>
        <h5 className="font-size-14">PayPal</h5>
      </div>
      <div className="mt-4">
        <p className="text-muted mb-1">Billing information</p>
        <h5 className="font-size-14">
          Orthoplex Solutions 84A Abdel-Aziz Fahmi St Heliopolis, Cairo Egypt
        </h5>
      </div>
    </div>
  );
};

export default Account;
