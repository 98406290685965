import React from "react";
import { Link } from "react-router-dom";
import img1 from "../../../assets/images/users/avatar-4.jpg";
import { useDispatch } from "react-redux";
import { sideBarActions } from "../../../redux/sideBar/sideBarSlice";
import DropDown from "../../../components/DropDown/index";
import {
  Form,
  Input,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
} from "reactstrap";
import { useState } from "react";

const AdminUsersSettings = () => {
  const dispatch = useDispatch();
  return (
    <div className="px-2 py-2 mt-3">
      <ul style={{ listStyle: "none", padding: "0" }}>
        <li style={{ height: "50px" }}>
          <div
            to="#"
            className="d-flex justify-content-between align-items-center"
            onClick={() => {
              // dispatch(
              //   sideBarActions.changeOverlay({
              //     overlay: "analytics",
              //   })
              // );

              dispatch(sideBarActions.showOverlay());
            }}
          >
            <div className="d-flex align-items-center gap-3">
              <img src={img1} className="rounded-circle avatar-xs" alt="" />

              <div className="overflow-hidden d-flex gap-5 ">
                <h5 className="font-size-14 mb-0">User Full Name</h5>
              </div>
            </div>

            <DropDown user="user" />
          </div>
        </li>
      </ul>
    </div>
  );
};

export default AdminUsersSettings;
