import React, { useEffect, useState } from "react";

// hooks
// import { useRedux } from "../../../hooks/index";

// actions
// import {
//   toggleUserDetailsTab,
//   getChatUserConversations,
//   onSendMessage,
//   receiveMessage,
//   readMessage,
//   receiveMessageFromUser,
//   deleteMessage,
//   deleteUserMessages,
//   toggleArchiveContact,
// } from "../../../redux/actions";

// hooks
// import { useProfile } from "../../../hooks";

// components
import UserHead from "./UserHead";
import Conversation from "./Conversation";
import ChatInputSection from "./ChatInputSection/index";
import UserHistoryAdmin from "./UserHistoryAdmin";

// interface
// import { MessagesTypes } from "../../../data/messages";

// dummy data
// import { pinnedTabs } from "../../../data/index";

const Index = ({ isChannel }) => {
  // global store
  // const { dispatch, useAppSelector } = useRedux();

  // const {
  //   chatUserDetails,
  //   chatUserConversations,
  //   isUserMessageSent,
  //   isMessageDeleted,
  //   isMessageForwarded,
  //   isUserMessagesDeleted,
  //   isImageDeleted,
  // } = useAppSelector((state) => ({
  //   chatUserDetails: state.Chats.chatUserDetails,
  //   chatUserConversations: state.Chats.chatUserConversations,
  //   isUserMessageSent: state.Chats.isUserMessageSent,
  //   isMessageDeleted: state.Chats.isMessageDeleted,
  //   isMessageForwarded: state.Chats.isMessageForwarded,
  //   isUserMessagesDeleted: state.Chats.isUserMessagesDeleted,
  //   isImageDeleted: state.Chats.isImageDeleted,
  // }));

  const onOpenUserDetails = () => {
    // dispatch(toggleUserDetailsTab(true));
  };

  /*
  hooks
  */
  // const { userProfile } = useProfile();

  /*
  reply handeling
  */
  const [replyData, setReplyData] = useState();
  const onSetReplyData = (reply) => {
    setReplyData(reply);
  };

  /*
  send message
  */
  const onSend = (data) => {
    let params = {
      text: data.text && data.text,
      time: new Date().toISOString(),
      image: data.image && data.image,
      newimage: data.newimage && data.newimage,
      attachments: data.attachments && data.attachments,
      meta: {
        // receiver: chatUserDetails.id,
        // sender: userProfile.uid,
      },
    };

    if (replyData && replyData !== null) {
      params["replyOf"] = replyData;
    }

    // dispatch(onSendMessage(params));
    if (!isChannel) {
      setTimeout(() => {
        // dispatch(receiveMessage(chatUserDetails.id));
      }, 1000);
      setTimeout(() => {
        // dispatch(readMessage(chatUserDetails.id));
      }, 1500);
      setTimeout(() => {
        // dispatch(receiveMessageFromUser(chatUserDetails.id));
      }, 2000);
    }
    setReplyData(null);
  };

  // useEffect(() => {
  //   if (
  //     // isUserMessageSent ||
  //     isMessageDeleted ||
  //     isMessageForwarded ||
  //     isUserMessagesDeleted ||
  //     isImageDeleted
  //   ) {
  //     // dispatch(getChatUserConversations(chatUserDetails.id));
  //   }
  // }, [
  //   // dispatch,
  //   isUserMessageSent,
  //   chatUserDetails,
  //   isMessageDeleted,
  //   isMessageForwarded,
  //   isUserMessagesDeleted,
  //   isImageDeleted,
  // ]);

  const onDeleteMessage = (messageId) => {
    // dispatch(deleteMessage(chatUserDetails.id, messageId));
  };

  const onDeleteUserMessages = () => {
    // dispatch(deleteUserMessages(chatUserDetails.id));
  };

  const onToggleArchive = () => {
    // dispatch(toggleArchiveContact(chatUserDetails.id));
  };
  return (
    <>
      <UserHead
        // chatUserDetails={chatUserDetails}
        // pinnedTabs={pinnedTabs}
        onOpenUserDetails={onOpenUserDetails}
        onDelete={onDeleteUserMessages}
        isChannel={isChannel}
        onToggleArchive={onToggleArchive}
      />
      <Conversation
        // chatUserConversations={chatUserConversations}
        // chatUserDetails={chatUserDetails}
        onDelete={onDeleteMessage}
        onSetReplyData={onSetReplyData}
        isChannel={isChannel}
      />
      <ChatInputSection
        onSend={onSend}
        replyData={replyData}
        onSetReplyData={onSetReplyData}
        // chatUserDetails={chatUserDetails}
      />
    </>
  );
};

export default Index;
