import React, { useState } from "react";
import { Input, Button,Row, Col } from "reactstrap";

const OverView = () => {
  const [hide, setHide] = useState(false);

  return (
    <div className="p-4">
      {/* <div className="row row-cols-3 d-flex"> */}
      {/* <div className="col d-flex flex-wrap flex-column gap-3"> */}

      <Row>
        <Col sm={4}>
          <div
            style={{
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              padding: "25px",
              height: "120px",
              marginBottom: "30px",
              borderRadius: "5px",
            }}
          >
            <div className="d-flex justify-content-between mb-2">
              <h5 className="">Company Name</h5>
              {!hide && (
                <Button
                  color="none"
                  type="button"
                  className="btn btn-soft-primary btn-sm"
                  onClick={() => {
                    setHide(!hide);
                  }}
                >
                  <i className="bx bxs-pencil align-middle"></i>
                </Button>
              )}
              {hide && (
                <Button
                  color="none"
                  type="button"
                  className="btn btn-soft-primary btn-sm"
                  onClick={() => {
                    setHide(!hide);
                  }}
                >
                  <i className="bx bxs-save align-middle"></i>
                </Button>
              )}
            </div>

            {!hide && <h4 className="font-size-14">Fresh</h4>}
            {hide && (
              <Input
                type="text"
                name="email"
                placeholder="Enter company name"
                className="form-control"
              />
            )}
          </div>
        </Col>
        <Col sm={4}>
          <div
            style={{
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              padding: "25px",
              height: "120px",
              marginBottom: "30px",
              borderRadius: "5px",
            }}
          >
            <h4 className="mb-1">Summary Last Payment</h4>
            <h5 className="mb-1">$1000</h5>
          </div>
          {/* </div> */}
        </Col>
        {/* 
        <div className="col d-flex flex-column gap-3"> */}
        <Col sm={4}>
          <div
            style={{
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              padding: "25px",
              height: "120px",
              marginBottom: "5px",
              borderRadius: "5px",
            }}
          >
            <h4 className="mb-2">Recurring Revenue</h4>
            <h5 className="mb-2">$1000</h5>
          </div>
        </Col>

        <Col sm={4}>
          <div
            style={{
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              padding: "25px",
              height: "120px",
              marginBottom: "5px",
              borderRadius: "5px",
            }}
          >
            <h4 className="mb-1">Lifetime Revenue</h4>
            <h5 className="mb-1">$1000</h5>
          </div>
        </Col>
        {/* </div> */}

        {/* <div className="col d-flex flex-column gap-3"> */}
        <Col sm={4}>
          <div
            style={{
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              padding: "25px",
              height: "120px",
              marginBottom: "5px",
              borderRadius: "5px",
            }}
          >
            <h4 className="mb-2">Number of Bots</h4>
            <h5 className="mb-2">8</h5>
          </div>
        </Col>
        <Col sm={4}>
          <div
            style={{
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              padding: "25px",
              height: "120px",
              marginBottom: "5px",
              borderRadius: "5px",
            }}
          >
            <h4 className="mb-1">Number of Users</h4>
            <h5 className="mb-1">100</h5>
          </div>
          {/* </div> */}
        </Col>
      </Row>
      {/* </div> */}
    </div>
  );
};

export default OverView;
