import React from "react";
import NonAuthLayoutWrapper from "../../../components/NonAutnLayoutWrapper";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
const ErrorPage = () => {
  return (
    <NonAuthLayoutWrapper>
      <Row className="justify-content-center my-auto">
        <Col sm={8} lg={6} xl={5} className="col-xxl-4">
          <div className="py-md-5 py-4 text-center">
            <div className="mt-4 pt-2">
              <h1 style={{ fontSize: "150px", color: "#4f46e5" }}>404</h1>
              <p style={{ fontSize: "30px", color: "#878a92" }}>
                {" "}
                Page Not Found
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </NonAuthLayoutWrapper>
  );
};

export default ErrorPage;
