import { createSlice } from "@reduxjs/toolkit";
const costPerTokens = 2;
const costPerUSers = 5;
const costPerDataSizeCost = 50;
const SubscriptionUserCartSlice = createSlice({
  name: "subscription",
  initialState: {
    tokens: null,
    users: null,
    dataSize: null,
    tokensCost: null,
    usersCost: null,
    dataSizeCost: null,
    total: null,
  },
  reducers: {
    changeTokens(state, action) {
      const tokensVal = action.payload.val;
      state.tokens = tokensVal;
      state.tokensCost = tokensVal * costPerTokens;
      state.total =
        tokensVal * costPerTokens + state.usersCost + state.dataSizeCost;
    },
    changeUsers(state, action) {
      const usersVal = action.payload.val;
      state.users = usersVal;
      state.usersCost = usersVal * costPerUSers;
      state.total =
        usersVal * costPerUSers + state.tokensCost + state.dataSizeCost;
    },
    changeDataSize(state, action) {
      const dataSizeVal = action.payload.val;
      state.dataSize = dataSizeVal;
      state.dataSizeCost = dataSizeVal * costPerDataSizeCost;
      state.total =
        dataSizeVal * costPerDataSizeCost + state.usersCost + state.tokensCost;
    },
  },
});

export const subscriptionActions = SubscriptionUserCartSlice.actions;
export default SubscriptionUserCartSlice;
