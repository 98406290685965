import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import UploadFile from "../pages/Dashbord/ManageData/FileUploader";
import AddButton from "../components/AddButton";
import AppSimpleBar from "../components/AppSimpleBar";

const UploadPopup = ({ isOpen, onClose, onInvite }) => {
  const [tab, setTab] = useState("upload");
  const [siteMap, setSiteMap] = useState("");
  const [sites, setSites] = useState([]);
  const [title, setTitle] = useState("");
  const [data, setData] = useState("");
  const [fullData, setFullData] = useState([]);
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const handleSubmit = () => {
    console.log({ websiteval: sites });
  };

  const text = fullData?.map((itm, idx) => {
    return (
      <li
        className="font-size-13 d-flex align-items-center gap-2"
        key={idx}
        style={{ listStyle: "none" }}
      >
        <div className="mt-2 w-100">
          <Accordion open={open} toggle={toggle}>
            <AccordionItem>
              <AccordionHeader targetId={idx} className="">
                <i className="bx bx-text" style={{ color: "#4f46e5" }}></i>
                {itm.titleT}
              </AccordionHeader>
              <AccordionBody accordionId={idx}>
                <ul style={{ listStyle: "none" }}>
                  <li style={{ height: "auto" }}>
                    <div className="d-flex justify-content-between align-contents-center">
                      <div
                        className="d-flex gap-3 align-items-center"
                        style={{ width: "100%" }}
                      >
                        <p> {itm.dataT}</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </div>
      </li>
      // <li
      //   className="font-size-13 d-flex align-items-center gap-2"
      //   key={idx}
      //   style={{ listStyle: "none" }}
      // >
      //   <div className="user-setting w-100">
      //     <div className="mt-3  ">
      //
      //     </div>
      //   </div>
      // </li>
    );
  });

  const links = sites?.map((itm, idx) => (
    <li
      className="font-size-13 d-flex align-items-center gap-2"
      key={idx}
      style={{ listStyle: "none" }}
    >
      <i className="bx bx-link" style={{ color: "#4f46e5" }}></i>
      <p className="m-0">{itm}</p>
    </li>
  ));

  return (
    <Modal
      className="modal-size"
      isOpen={isOpen}
      toggle={onClose}
      tabIndex={-1}
      centered
      scrollable
      size="lg"
    >
      <Form
        className="d-flex flex-column justify-content-between h-100"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
          return false;
        }}
      >
        <div>
          <ModalHeader className="modal-title-custom" toggle={onClose}>
            Upload Data
          </ModalHeader>
          <ul className="nav nav-tabs-new2">
            <li className="nav-item inlineblock">
              <p
                className={`nav-link ${tab === "upload" ? "active" : null}`}
                onClick={() => {
                  setTab("upload");
                }}
                style={{ cursor: "pointer", color: tab ? "" : "#4f46e5" }}
              >
                File
              </p>
            </li>
            <li className="nav-item inlineblock">
              <p
                className={`nav-link ${tab === "link" ? "active" : null}`}
                onClick={() => {
                  setTab("link");
                }}
                style={{ cursor: "pointer", color: tab ? "#4f46e5" : "" }}
              >
                Link
              </p>
            </li>
            <li className="nav-item inlineblock">
              <p
                className={`nav-link ${tab === "text" ? "active" : null}`}
                onClick={() => {
                  setTab("text");
                }}
                style={{ cursor: "pointer", color: tab ? "#4f46e5" : "" }}
              >
                Text
              </p>
            </li>
          </ul>
          <div className="tab-content px-3">
            <div className={`tab-pane ${tab === "upload" ? "active" : null} `}>
              <p style={{ marginBottom: "3px", fontSize: "px" }}>
                Upload your files to train{" "}
              </p>
              <UploadFile />
            </div>
            <div className={`tab-pane ${tab === "link" ? "active" : null}`}>
              <div className="mb-3">
                <p style={{ marginBottom: "3px", fontSize: "px" }}>
                  Website sitemap
                </p>
                <div className="d-flex">
                  <Input
                    type="text"
                    className="form-control"
                    id="websiteval"
                    placeholder="Enter website sitemap"
                    onChange={(e) => setSiteMap(e.target.value)}
                    onBlur={(e) => setSiteMap(e.target.value)}
                    value={siteMap}
                  />
                  <Button
                    className=""
                    color="secondary"
                    onClick={() => {
                      if (siteMap !== "") {
                        setSites([...sites, siteMap]);
                      }
                      setSiteMap("");
                    }}
                  >
                    Add
                  </Button>
                </div>
                <ul style={{ padding: "0", marginTop: "15px", opacity: "0.7" }}>
                  {links}
                </ul>
              </div>
            </div>
            <div className={`tab-pane ${tab === "text" ? "active" : null}`}>
              <div className="mb-3">
                <p style={{ marginBottom: "3px", fontSize: "px" }}>Text</p>
                <div className="d-flex  gap-3">
                  <div className="d-flex flex-column gap-2 w-100">
                    <Input
                      type="text"
                      className="form-control"
                      id="titleval"
                      placeholder="Enter title"
                      onChange={(e) => setTitle(e.target.value)}
                      onBlur={(e) => setTitle(e.target.value)}
                      value={title}
                    />
                    <textarea
                      type="text"
                      className="form-control"
                      id="textval"
                      placeholder="Enter a text"
                      onChange={(e) => setData(e.target.value)}
                      onBlur={(e) => setData(e.target.value)}
                      style={{ resize: "none" }}
                    />
                  </div>
                  <div className="mt-1">
                    <AddButton
                      onClick={() => {
                        if (title !== "" && data !== "") {
                          setFullData([
                            ...fullData,
                            { titleT: title, dataT: data },
                          ]);
                        }
                      }}
                    />
                  </div>
                </div>

                <AppSimpleBar className="scroll-size">
                  <ul
                    style={{ padding: "0", marginTop: "15px", opacity: "0.7" }}
                  >
                    {text}
                  </ul>
                </AppSimpleBar>
              </div>
            </div>
          </div>
        </div>
        <ModalFooter>
          <Button type="button" color="link" className="btn" onClick={onClose}>
            Close
          </Button>
          <Button color="primary" type="submit">
            Upload
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default UploadPopup;
