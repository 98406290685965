import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import AppSimpleBar from "../../../components/AppSimpleBar";
import DropDown from "../../../components/DropDown";
import { useDispatch } from "react-redux";
import { sideBarActions } from "../../../redux/sideBar/sideBarSlice";
const BotSideBar = () => {
  const dispatch = useDispatch();
  return (
    <>
      <div className="px-4 pt-4 card ">
        <div className="d-flex align-items-start">
          <div className="flex-grow-1 d-flex justify-content-between">
            <h4 className="mb-4">Manage Bot</h4>
            <div>
              <button
                type="button"
                className="btn btn-soft-primary btn-sm pb-0"
                id="addBot"
              >
                <i className="bx bx-plus"></i>
                <UncontrolledTooltip target={"addBot"} placement="bottom">
                  Add Bot
                </UncontrolledTooltip>
              </button>
            </div>
          </div>
        </div>
        {/* <UploadFile /> */}
        <div className="flex-grow-1 mt-3">
          <h4 className="mb-0 font-size-11 text-muted text-uppercase">Bots</h4>
        </div>
        <AppSimpleBar className="user-setting">
          <ul className="mt-3" style={{ listStyle: "none", padding: "0" }}>
            <li
              style={{ height: "40px" }}
              className="bot-click"
              onClick={() => {
                dispatch(sideBarActions.showOverlay());
                dispatch(sideBarActions.chooseBot(true));
                dispatch(sideBarActions.historyChat(false));
              }}
            >
              <div className="d-flex justify-content-between align-contents-center">
                <div className="d-flex align-items-center justify-content-between w-100">
                  <div className="d-flex gap-3 align-items-center">
                    <i className="bx bxs-bot"></i>
                    <span> Sales</span>
                  </div>
                  <DropDown user="admin" />
                </div>
              </div>
            </li>
            <li
              style={{ height: "40px" }}
              className="bot-click"
              onClick={() => {
                dispatch(sideBarActions.showOverlay());
                dispatch(sideBarActions.chooseBot(true));
                dispatch(sideBarActions.historyChat(false));
              }}
            >
              <div className="d-flex justify-content-between align-contents-center">
                <div className="d-flex gap-3 align-items-center justify-content-between w-100">
                  <div className="d-flex gap-3 align-items-center">
                    <i className="bx bxs-bot"></i>
                    <span>Financial</span>
                  </div>
                  <DropDown user="admin" />
                </div>
              </div>
            </li>
          </ul>
        </AppSimpleBar>
        {/* <div className="d-flex justify-content-center" style={{ width: "100%" }}>
            <button className="btn btn-primary px-5  mt-3 ">Retrain</button>
          </div> */}
      </div>
    </>
  );
};

export default BotSideBar;
