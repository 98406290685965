import React, { useState } from "react";
import { Button, Collapse } from "reactstrap";
import classnames from "classnames";

import { SETTINGS_COLLAPSES } from "../../../constants/settings";

import AppSimpleBar from "../../../components/AppSimpleBar";
import UserCoverImage from "./UserCoverImage";
import UserProfile from "./UserProfile";
import PersonalInfo from "./PersonalInfo";
import Account from "./Account";

import Security from "./Security";
import Help from "./Help";

const AccordianItem = ({ item, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  return (
    <div className="accordion-item">
      <div className="accordion-header" id="headerpersonalinfo">
        <Button
  
          color="none"
          className={classnames(
            "accordion-button",
            "font-size-14",
            "fw-medium",
            { collapsed: !isOpen }
          )}
          onClick={toggleCollapse}
          type="button"
        >
          <i className={classnames("text-muted", "me-3", item.icon)}></i>{" "}
          {item.label}
        </Button>
      </div>
      <Collapse
        isOpen={isOpen}
        id="personalinfo"
        className="accordion-collapse"
      >
        {item.component}
      </Collapse>
    </div>
  );
};

const Index = (props) => {
  const [selectedMenu, setSelectedMenu] = useState(null);

  const collapseItems = [
    {
      value: SETTINGS_COLLAPSES.ACCOUNT,
      label: "Account",
      icon: "bx bxs-buildings",
      component: <Account />,
    },
    {
      value: SETTINGS_COLLAPSES.PROFILE,
      label: "Personal Info",
      icon: "bx bxs-user",
      component: <PersonalInfo />,
    },

    {
      value: SETTINGS_COLLAPSES.SECURITY,
      label: "Security",
      icon: "bx bxs-check-shield",
      component: <Security />,
    },
    {
      value: SETTINGS_COLLAPSES.HELP,
      label: "Help",
      icon: "bx bxs-help-circle",
      component: <Help />,
    },
  ];

  const onChangeCollapse = (id) => {
    const { PROFILE, HELP, PRIVACY, SECURITY, THEME } = SETTINGS_COLLAPSES;

    setSelectedMenu(id);
  };

  return (
    <div className="">
      {/* <UserCoverImage /> */}
      <UserProfile />

      <AppSimpleBar className="user-setting">
        <div id="settingprofile" className="accordion accordion-flush">
          {(collapseItems || []).map((item, key) => (
            <AccordianItem item={item} key={key} onChange={onChangeCollapse} />
          ))}
        </div>
      </AppSimpleBar>
    </div>
  );
};

export default Index;
