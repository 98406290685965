import React from "react";
import { Navigate } from "react-router-dom";

const Index = (props) => {
  return (
    <div>
      <Navigate to="/pages-starter" />
    </div>
  );
};

export default Index;
