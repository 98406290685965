import React from "react";
import { Label } from "reactstrap";

import img1 from "../../../assets/images/users/avatar-2.jpg";

const UserProfile = () => {
  return (
    <div className=" p-3 p-lg-4 border-bottom ">
      <div className="flex-grow-1">
        <h5 className="mb-0">Settings</h5>
      </div>
      <div className="text-center pt-5">
        <div className="mb-3  profile-user">
          <img
            src={img1}
            className="rounded-circle avatar-lg img-thumbnail user-profile-image"
            alt="user-profile"
          />
          <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
            <input
              // onChange={(e) => onChangeProfile(e)}
              id="profile-img-file-input"
              type="file"
              className="profile-img-file-input"
              accept="image/png, image/jpeg"
            />
            <Label
              htmlFor="profile-img-file-input"
              className="profile-photo-edit avatar-xs"
            >
              <span className="avatar-title rounded-circle bg-light text-body">
                <i className="bx bxs-camera"></i>
              </span>
            </Label>
          </div>
        </div>

        <h5 className="font-size-16 mb-1 text-truncate">User Name</h5>
      </div>
    </div>
  );
};

export default UserProfile;
