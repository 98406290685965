import React from "react";
import { Col, Row } from "reactstrap";

import { useDispatch } from "react-redux";
import { sideBarActions } from "../../../redux/sideBar/sideBarSlice";

const WelcomeManagedData = () => {
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <div className="chat-welcome-section">
        <Row className="w-100 justify-content-center">
          <Col xxl={5} md={7}>
            <div className="p-4 text-center d-flex justify-content-center flex-column align-items-center">
              <div className="avatar-xl mx-auto mb-4">
                <div className="avatar-title bg-soft-primary rounded-circle">
                  <i className="bx bxs-message-alt-detail display-4 text-primary m-0"></i>
                </div>
              </div>
              <h4>Welcome to Beky.ai</h4>
              <p className="text-muted mb-4">
                Experience engineer consulting like never before!
              </p>
              <h5 className="mb-3">Select a bot ...</h5>
              <div className="d-flex flex-wrap ">
                <div
                  className="d-flex justify-content-start align-items-center gap-2 m-2 bot-hover"
                  style={{
                    padding: "8px 16px",
                    backgroundColor: "white",
                    borderRadius: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    dispatch(sideBarActions.chooseBot(true));
                  }}
                >
                  <i className="bx bxs-bot"></i>

                  <div className="text-muted">Sales</div>
                </div>
                <div
                  className="d-flex justify-content-start align-items-center gap-2 m-2 bot-hover"
                  style={{
                    padding: "8px 16px",
                    backgroundColor: "white",
                    borderRadius: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    dispatch(sideBarActions.chooseBot(true));
                  }}
                >
                  <i className="bx bxs-bot"></i>

                  <div className="text-muted">Financial</div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default WelcomeManagedData;
