import React from "react";
import {
  Chart,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  registerables,
} from "chart.js";

import "chartjs-plugin-datalabels";
import { Line } from "react-chartjs-2";
const LineChart = ({
  dataValues1,
  dataValues2,
  label1,
  label2,
  currencyAppear,
}) => {
  Chart.register(
    ArcElement,
    Tooltip,
    Legend,
    Title,
    CategoryScale,
    ...registerables
  );
  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];



  let data = {
    labels: labels,
    datasets: [
      {
        label: label1,
        data: dataValues1,
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
        hoverOffset: 5,
      },
      {
        label: label2,
        data: dataValues2,
        fill: false,
        borderColor: "#17123c",
        tension: 0.1,
        hoverOffset: 5,
      },
    ],
  };

  let options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "User base",
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            if (currencyAppear) {
              if (label) {
                label += ": ";
              }
              if (context.parsed.y !== null) {
                label += new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(context.parsed.y);
              }
            }

            return label;
          },
        },
      },
    },
  };
  return (
    <>
      <div className="custom-chart">
        <Line data={data} options={options} height={300} width={350} />
      </div>
    </>
  );
};

export default LineChart;
