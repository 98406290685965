import { configureStore } from "@reduxjs/toolkit";
import sideBarSlice from "./sideBar/sideBarSlice";
import SubscriptionUserCartSlice from "./SubscriptionUserCart/SubscriptionUserCartSlice";
import ThemeSlice from "./theme/ThemeSlice";
const store = configureStore({
  reducer: {
    sideBar: sideBarSlice.reducer,
    subscription:SubscriptionUserCartSlice.reducer,
    themeMode:ThemeSlice.reducer,
  },
});
export default store;
