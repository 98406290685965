import React from "react";
import NonAuthLayoutWrapper from "../../../components/NonAutnLayoutWrapper";
import AuthHeader from "../../../components/AuthHeader";
import FormInput from "../../../components/FormInput";
import Loader from "../../../components/Loader";
import * as Yup from "yup";
import { useFormik } from "formik";
import avatar1 from "../../../assets/images/users/avatar-1.jpg";
import {
  Alert,
  Row,
  Col,
  Form,
  Label,
  Button,
  UncontrolledTooltip,
  Input,
} from "reactstrap";

const ChangePassword = () => {
  const passwordRegex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      oldPassword: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .matches(
          passwordRegex,
          "Password Must Contain min 8 Characters, 1 Letter, 1 Number and 1 special character:"
        )
        .required("Please Enter Your Password"),
      password: Yup.string()
        .matches(
          passwordRegex,
          "Password Must Contain min 8 Characters, 1 Letter, 1 Number and 1 special character:"
        )
        .required("Please Enter Your Password"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Required"),
    }),
    onSubmit: (values) => {
      // onChangePassword(token, values.password, values.confirmPassword);
      console.log({
        oldPassword: values.oldPassword,
        newPassword: values.password,
        confirmPassword: values.confirmPassword,
      });
    },
  });

  return (
    <NonAuthLayoutWrapper>
      <Row className=" justify-content-center my-auto">
        <Col sm={8} lg={6} xl={5} className="col-xxl-4">
          <div className="py-md-5 py-4">
            <AuthHeader title="Change Password" />
            <div className="user-thumb text-center mb-4">
              <img
                src={avatar1}
                className="rounded-circle img-thumbnail avatar-lg"
                alt="thumbnail"
              />
              <h5 className="font-size-15 mt-3">Kathryn Swarey</h5>
            </div>
            {/* {changepasswordError && changepasswordError ? (
              <Alert color="danger">{changepasswordError}</Alert>
            ) : null}
            {passwordChanged ? (
              <Alert color="success">Your Password is changed</Alert>
            ) : null} */}

            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              className="position-relative"
            >
              {/* {changePassLoading && <Loader />} */}
              <Label htmlFor="Password" className="form-label">
                Old Password
              </Label>
              <div className="mb-3">
                <Input
                  label="OldPassword"
                  type="password"
                  name="oldPassword"
                  placeholder="Enter Old Password"
                  className="form-control"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.oldPassword || ""}
                />
                {validation.touched.oldPassword &&
                validation.errors.oldPassword ? (
                  <p style={{ color: "red" }}>
                    {validation.errors.oldPassword}
                  </p>
                ) : null}
              </div>
              <Label htmlFor="Password" className="form-label">
                Password
              </Label>
              <div className="mb-3">
                <Input
                  label="password"
                  type="password"
                  name="password"
                  placeholder="Enter New Password"
                  className="form-control"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.password || ""}
                />
                {validation.touched.password && validation.errors.password ? (
                  <p style={{ color: "red" }}>{validation.errors.password}</p>
                ) : null}
              </div>

              <Label htmlFor="Password" className="form-label">
                Confirm Password
              </Label>
              <div className="mb-3">
                <Input
                  label="confirmPassword"
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm new Password"
                  className="form-control"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.confirmPassword || ""}
                />
                {validation.touched.confirmPassword &&
                validation.errors.confirmPassword ? (
                  <p style={{ color: "red" }}>
                    {validation.errors.confirmPassword}
                  </p>
                ) : null}
              </div>

              <div className="text-center mt-4">
                <div className="row">
                  <div className="col-6">
                    <button className="btn btn-primary w-100" type="submit">
                      Save
                    </button>
                  </div>
                  <div className="col-6">
                    <button className="btn btn-light w-100" type="button">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </NonAuthLayoutWrapper>
  );
};

export default ChangePassword;
