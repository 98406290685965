import React from "react";
import { Row, Col } from "reactstrap";

import { useDispatch } from "react-redux";
import { sideBarActions } from "../redux/sideBar/sideBarSlice";

const ProfileImage = () => {
  const dispatch = useDispatch();

  return (
    <div className="d-flex align-items-center">
      <div className="flex-shrink-0 d-block d-lg-none me-2">
        <div
          onClick={() => {
            dispatch(sideBarActions.hideOverlay());
          }}
          className="user-chat-remove text-muted font-size-24 p-2"
        >
          <i className="bx bx-chevron-left align-middle"></i>
        </div>
      </div>
    </div>
  );
};

const TopBarResponsive = () => {
  return (
    <div className="p-3 p-lg-4 user-chat-topbar display">
      <Row className="align-items-center">
        <Col sm={4} className="col-8">
          <ProfileImage />
        </Col>
      </Row>
    </div>
  );
};

export default TopBarResponsive;
