// import React, { useState } from "react";
import { Row, Col, Form, Label, Button, Input } from "reactstrap";
import { Link } from "react-router-dom";
import NonAuthLayoutWrapper from "../../../components/NonAutnLayoutWrapper";
import AuthHeader from "../../../components/AuthHeader";
// import FormInput from "../../../components/FormInput";
// import Loader from "../../../components/Loader";
import * as Yup from "yup";

import { useFormik } from "formik";

const Login = () => {
  const validation = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    enableReinitialize: false,
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Invalid email format")
        .email("Please Enter Valid Email")
        .required("Please Enter E-mail."),
      password: Yup.string()
        // .matches(
        //   /((?=.\d)(?=.[a-zA-Z])(?=.[!@#$%^&()])(?!.* ).{8,20})/,
        //   "Invalid Password Fromat"
        // )
        .required("Please Enter Password."),
    }),
    onSubmit: (values) => {
      console.log({ mail: values.email, password: values.password });
    },
  });

  // const defaultValues = {
  //   email: "admin@themesbrand.com",
  //   password: "123456",
  // };

  return (
    <>
      <NonAuthLayoutWrapper>
        <Row className=" justify-content-center my-auto">
          <Col sm={8} lg={6} xl={5} className="col-xxl-4">
            <div className="py-md-5 py-4">
              <AuthHeader
                title="Welcome Back!"
                subtitle="Sign in to continue to Beky.ai."
              />

              {/* {error && <Alert color="danger">{error}</Alert>} */}

              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
                className="position-relative"
              >
                {/* {loginLoading && <Loader />} */}
                <Label htmlFor="email" className="form-label">
                  Username
                </Label>
                <div className="mb-3">
                  <Input
                    label="Username"
                    type="text"
                    name="email"
                    placeholder="Enter username"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <p style={{ color: "red" }}>{validation.errors.email}</p>
                  ) : null}
                </div>
                <Label htmlFor="Password" className="form-label">
                  Password
                </Label>
                <div className="float-end">
                  <Link to="/auth-recoverpw" className="text-muted">
                    Forgot password?
                  </Link>
                </div>
                <div className="mb-3">
                  <Input
                    label="Password"
                    type="password"
                    name="password"
                    className="form-control pe-5"
                    placeholder="Enter Password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.password || ""}
                  />
                  {validation.touched.password && validation.errors.password ? (
                    <p style={{ color: "red" }}>{validation.errors.password}</p>
                  ) : null}
                </div>

                <div className="form-check form-check-info font-size-16">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="remember-check"
                  />
                  <Label
                    className="form-check-label font-size-14"
                    htmlFor="remember-check"
                  >
                    Remember me
                  </Label>
                </div>

                <div className="text-center mt-4">
                  <Button color="primary" className="w-100" type="submit">
                    Log In
                  </Button>
                </div>
              </Form>

              <div className="mt-5 text-center text-muted">
                <p>
                  Don't have an account?{" "}
                  <Link
                    to="/auth-register"
                    className="fw-medium text-decoration-underline"
                  >
                     Register
                  </Link>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </NonAuthLayoutWrapper>
    </>
  );
};

export default Login;
