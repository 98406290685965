import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { sideBarActions } from "../../redux/sideBar/sideBarSlice";
import { Link } from "react-router-dom";
import {
  Nav,
  NavItem,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  NavLink,
  UncontrolledTooltip,
} from "reactstrap";

import LightDarkMode from "../../components/LightDarkMode";
import avatar1 from "../../assets/images/users/avatar-1.jpg";

const LogoLightSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 24 24"
    >
      <path d="M8.5,18l3.5,4l3.5-4H19c1.103,0,2-0.897,2-2V4c0-1.103-0.897-2-2-2H5C3.897,2,3,2.897,3,4v12c0,1.103,0.897,2,2,2H8.5z M7,7h10v2H7V7z M7,11h7v2H7V11z" />
    </svg>
  );
};

const LogoDarkSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 24 24"
    >
      <path d="M8.5,18l3.5,4l3.5-4H19c1.103,0,2-0.897,2-2V4c0-1.103-0.897-2-2-2H5C3.897,2,3,2.897,3,4v12c0,1.103,0.897,2,2,2H8.5z M7,7h10v2H7V7z M7,11h7v2H7V11z" />
    </svg>
  );
};

const Logo = () => {
  return (
    <div className="navbar-brand-box ">
      <Link to="#" className="logo logo-dark">
        <span className="logo-sm">
          <LogoLightSVG />
        </span>
      </Link>

      <Link to="#" className="logo logo-light">
        <span className="logo-sm">
          <LogoDarkSVG />
        </span>
      </Link>
    </div>
  );
};

const SideMenu = () => {
  const [tab, setTab] = useState("chat");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      sideBarActions.changeTab({
        tab: tab,
      })
    );
  }, [dispatch, tab]);
  const ProfileDropdownMenu = ({ onChangeTab }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(!dropdownOpen);

    return (
      <Dropdown
        nav
        isOpen={dropdownOpen}
        className="profile-user-dropdown"
        toggle={toggle}
      >
        <DropdownToggle nav className="bg-transparent">
          <img src={avatar1} alt="" className="profile-user rounded-circle" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={() => {
              setTab("setting");
            }}
            className="d-flex align-items-center justify-content-between"
          >
            Setting <i className="bx bx-cog text-muted ms-1"></i>
          </DropdownItem>
          <DropdownItem
            className="d-flex align-items-center justify-content-between"
            href="/auth-changepassword"
          >
            Change Password <i className="bx bx-lock-open text-muted ms-1"></i>
          </DropdownItem>

          <DropdownItem
            className="d-flex align-items-center justify-content-between"
            tag="a"
            href="/logout"
          >
            Log out <i className="bx bx-log-out-circle text-muted ms-1"></i>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };
  return (
    <div className="side-menu flex-lg-column fixed-p">
      <Logo />

      <div className="flex-lg-column my-0 sidemenu-navigation">
        <Nav pills className="side-menu-nav" role="tablist">
          <>
            <NavItem>
              <NavLink
                href="#"
                id="superAdmin"
                role="tab"
                style={{ display: "flex", justifyContent: "center" }}
                className={` ${tab === "superAdmin" ? "active " : ""} `}
                onClick={() => {
                  setTab("superAdmin");
                }}
              >
                <div
                  style={{
                    width: "22px",
                    fill: "#878a92",
                    fontSize: "5px",
                  }}
                >
                  <svg
                    id="Layer_1"
                    data-name="Layear 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 433.57 470.81"
                    style={{ fill: tab === "superAdmin" ? "#9A96FC" : "" }}
                  >
                    <defs></defs>
                    <path
                      id="Light_Icon"
                      data-name="Light Icon"
                      className="cls-1 "
                      style={{ fontSize: "10px" }}
                      d="M175.51,448.93c12.89-12.89,20.17-29.45,21.65-49.21,1.15-15.4-1.21-32.7-7.22-52.89-10.19-34.2-29.82-72.66-48.81-109.86-7.37-14.44-14.9-29.21-21.82-43.61,25.7-10.25,58.37-15.42,97.48-15.42s71.77,5.17,97.47,15.42c-6.92,14.4-14.45,29.16-21.82,43.61-19,37.2-38.62,75.66-48.8,109.86-6,20.19-8.38,37.49-7.23,52.89,1.48,19.76,8.77,36.32,21.65,49.21h0c10.74,10.73,23,17.61,36.54,20.44a67.24,67.24,0,0,0,13.86,1.44,75.25,75.25,0,0,0,25.31-4.56c24.2-8.65,47.72-29.63,66.2-59.07a223.14,223.14,0,0,0,32.62-94.76c3-30.12-.88-58.08-11.53-83.08a143.47,143.47,0,0,0-43.88-57.13c6.92-23.48,7.7-46.71,2.18-68.12A113.36,113.36,0,0,0,356,59.24C344.22,45.2,328.59,33,309.56,23A198.82,198.82,0,0,0,124,23C105,33,89.35,45.2,77.55,59.24a113.36,113.36,0,0,0-23.34,44.85c-5.52,21.41-4.74,44.64,2.18,68.12a143.56,143.56,0,0,0-43.88,57.13C1.86,254.34-2,282.3,1,312.42A223.27,223.27,0,0,0,33.6,407.18c18.48,29.44,42,50.42,66.21,59.07C113.08,471,126.25,472,139,469.37c13.51-2.83,25.8-9.71,36.54-20.44ZM140.18,413.6c-7.24,7.24-14.29,8.91-23.57,5.6-13.3-4.75-28.52-19.18-40.71-38.59a172.49,172.49,0,0,1-25.21-73.13c-2.59-26.11.72-60.07,26.37-86.74,6.35,13,13,26.1,19.58,38.94,18,35.2,36.54,71.59,45.42,101.41C153.68,400.1,143.52,410.26,140.18,413.6Zm153.21,0c-3.34-3.34-13.5-13.5-1.88-52.51,8.89-29.82,27.46-66.21,45.43-101.41,6.55-12.84,13.23-25.93,19.57-38.94,25.65,26.67,29,60.63,26.37,86.74a172.35,172.35,0,0,1-25.21,73.13C345.48,400,330.26,414.45,317,419.2,307.68,422.51,300.63,420.84,293.39,413.6Zm38.51-267C300.3,134.25,261.74,128,216.79,128s-83.52,6.27-115.12,18.66a72.44,72.44,0,0,1,.92-30.08c5.12-19.83,20.16-36.45,44.72-49.4a149,149,0,0,1,139,0c24.55,13,39.6,29.57,44.71,49.4A72.44,72.44,0,0,1,331.9,146.64Z"
                    />
                  </svg>
                  <UncontrolledTooltip target={"superAdmin"} placement="right">
                    superAdmin
                  </UncontrolledTooltip>
                </div>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                role="tab"
                id="chat"
                className={`${tab === "chat" ? "active" : ""}`}
                onClick={() => {
                  setTab("chat");
                }}
              >
                <i className={`bx bx-conversation`}>
                  <UncontrolledTooltip target={"chat"} placement="right">
                    Chat
                  </UncontrolledTooltip>
                </i>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                href="#"
                id="upload-data"
                role="tab"
                className={` ${tab === "Manage Data" ? "active" : ""}`}
                onClick={() => {
                  setTab("Manage Data");
                  dispatch(sideBarActions.chooseBot(false));
                  dispatch(sideBarActions.historyChat(false));
                  dispatch(sideBarActions.hideOverlay());
                }}
              >
                <i className="bx bx-sitemap">
                  <UncontrolledTooltip target={"upload-data"} placement="right">
                    Manage Data
                  </UncontrolledTooltip>
                </i>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                id="users"
                role="tab"
                className={` ${tab === "users" ? "active" : ""}`}
                onClick={() => {
                  setTab("users");
                }}
              >
                <i className={`bx bx-group`}>
                  <UncontrolledTooltip target={"users"} placement="right">
                    Users
                  </UncontrolledTooltip>
                </i>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                id="settings"
                role="tab"
                className={` ${tab === "setting" ? "active" : ""}`}
                onClick={() => {
                  setTab("setting");
                }}
              >
                <i className={`bx bx-cog`}>
                  <UncontrolledTooltip target={"settings"} placement="right">
                    Settings
                  </UncontrolledTooltip>
                </i>
              </NavLink>
            </NavItem>
          </>

          <LightDarkMode />

          <ProfileDropdownMenu />
        </Nav>
      </div>
    </div>
  );
};

export default SideMenu;
