import React from "react";
import BarChart from "./barChart";
import { Row, Col } from "reactstrap";
import CardIcon from "../../../components/CardIcon";
const ManageDashboard = () => {
  return (
    <div className="p-1">
      <div className="pt-3">
        {/* <div className="d-flex  gap-3 "> */}
        <Row className="px-4 ">
          <Col sm={6} xxl={3}>
            <div
              className="d-flex align-items-center mb-3"
              style={{
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",

                padding: "15px",
                borderRadius: "5px",
                width: "100%",
                height: "fit-content",
              }}
            >
              <div className="d-flex align-items-center gap-3">
                <CardIcon>
                  <i className="bx bx-hash"></i>
                </CardIcon>

                <div>
                  <h4 style={{ fontWeight: "bold" }}>
                    {" "}
                    134347
                  </h4>
                  <p
                    style={{
                      fontSize: "14px",
                      textTransform: "uppercase",
                      margin: "0",
                    }}
                  >
                    Characters
                  </p>
                </div>
              </div>
            </div>
          </Col>

          <Col sm={6} xxl={3}>
            <div
              className="d-flex align-items-center mb-3"
              style={{
                boxShadow:
                  "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                padding: "15px",
                borderRadius: "5px",
                width: "100%",
                height: "fit-content",
              }}
            >
              <div className="d-flex align-items-center gap-3">
                <CardIcon>
                  {" "}
                  <i className="bx bx-message-dots"></i>
                </CardIcon>

                <div>
                  <h4 style={{ fontWeight: "bold" }}>
                    {" "}
                    314
                  </h4>
                  <p
                    style={{
                      fontSize: "14px",
                      textTransform: "uppercase",
                      margin: "0",
                    }}
                  >
                    chats
                  </p>
                </div>
              </div>
            </div>
          </Col>
          {/* </div> */}

          {/* <div className="d-flex  gap-3 "> */}

          <Col sm={6} xxl={3}>
            <div
              className="d-flex align-items-center mb-3"
              style={{
                boxShadow:
                  "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                padding: "15px",
                borderRadius: "5px",
                width: "100%",
                height: "fit-content",
              }}
            >
              <div className="d-flex align-items-center gap-3">
                <CardIcon>
                  {" "}
                  <i className="bx bx-coin"></i>
                </CardIcon>

                <div>
                  <h4 style={{ fontWeight: "bold" }}>
                    {" "}
                    21,000
                  </h4>
                  <p
                    style={{
                      fontSize: "14px",
                      textTransform: "uppercase",
                      margin: "0",
                    }}
                  >
                    Tokens used
                  </p>
                </div>
              </div>
            </div>
          </Col>

          <Col sm={6} xxl={3}>
            <div
              className="d-flex align-items-center mb-3"
              style={{
                boxShadow:
                  "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                padding: "15px",
                borderRadius: "5px",
                width: "100%",
                height: "fit-content",
              }}
            >
              <div className="d-flex align-items-center gap-3">
                <CardIcon>
                  {" "}
                  <i className="bx bx-brain"></i>
                </CardIcon>

                <div>
                  <h4 style={{ fontWeight: "bold" }}>
                    {" "}
                    15
                  </h4>
                  <p
                    style={{
                      fontSize: "14px",
                      textTransform: "uppercase",
                      margin: "0",
                    }}
                  >
                    Temperature
                  </p>
                </div>
              </div>
            </div>
          </Col>

          {/* </div> */}
        </Row>
      </div>

      <Row className=" " style={{ padding: "20px 26px 40px 26px" }}>
        <Col sm={12} md={7}>
          <div
            style={{
              boxShadow:
                "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              padding: "25px",
              borderRadius: "5px",
              width: "100%",
            }}
          >
            <div className=" my-3">
              <h4>Tokens</h4>
              <BarChart />
            </div>
          </div>
        </Col>

        <Col sm={12} md={5}>
          <div
            style={{
              boxShadow:
                "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              padding: "25px",
              borderRadius: "5px",
              width: "100%",
            }}
          >
            <div className="my-3">
              <h4>Recent Activities</h4>
              <ul
                style={{
                  listStyle: "none",
                  padding: "0",
                  margin: "0",
                }}
              >
                <li className="mb-2">Chat with Sales Bot at 2023/07/02</li>
                <li className="mb-2">Chat with Sales Bot at 2023/07/02</li>
                <li className="mb-2">Chat with Sales Bot at 2023/07/02</li>
                <li className="mb-2">Chat with Sales Bot at 2023/07/02</li>
                <li className="mb-2">Chat with Sales Bot at 2023/07/02</li>
              </ul>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ManageDashboard;
