import React, { useState, useMemo, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux/es/exports";

const UploadFile = () => {
  const themeChaneg = useSelector((state) => state.themeMode.themeMod);
  const [filePaths, setFilePaths] = useState([]);
  const deleteFilePath = (filePath) => {
    setFilePaths(filePaths.filter((path) => path !== filePath));
  };
  let bgColor = "";
  useEffect(() => {
    if (themeChaneg === "light") {
      bgColor = "#fafafa";
    } else {
      bgColor = "#333333";
    }
  }, [themeChaneg]);

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#4f46e5",
    borderStyle: "dashed",
    backgroundColor: bgColor,
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const focusedStyle = {
    borderColor: "#4f46e5",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: { "image/*": [] },
    onDrop: (droppedFiles) => {
      const newFilePaths = droppedFiles.map((file) => file.path);
      setFilePaths([...filePaths, ...newFilePaths]);
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const acceptedFileItems = filePaths.map((filePath) => (
    <li
      className="font-size-13 d-flex align-items-center gap-2"
      key={filePath}
      style={{ listStyle: "none", width: "100%" }}
    >
      <i className="bx bx-file" style={{ color: "#4f46e5" }}></i>

      <div className="d-flex justify-content-between" style={{ width: "95%" }}>
        <p style={{ width: "80%" }} className="m-0">
          {filePath}
        </p>

        <i
          onClick={() => deleteFilePath(filePath)}
          className="bx bx-trash"
          style={{ color: "" }}
        ></i>
      </div>
      {/* Delete button */}
    </li>
  ));
  return (
    <div>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p className="m-0 p-2">
          Drag & drop some files here, or click to select files
        </p>
      </div>
      <ul style={{ padding: "0", marginTop: "15px", opacity: "0.7" }}>
        {acceptedFileItems}
      </ul>
    </div>
  );
};

export default UploadFile;
