import React from "react";
import { Link, Navigate } from "react-router-dom";
import NonAuthLayoutWrapper from "../../../components/NonAutnLayoutWrapper";
import AuthHeader from "../../../components/AuthHeader";
import FormInput from "../../../components/FormInput";
import Loader from "../../../components/Loader";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Alert,
  Row,
  Col,
  Form,
  Button,
  UncontrolledTooltip,
  Input,
  Label,
} from "reactstrap";
const Register = () => {
  const passwordRegex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const validation = useFormik({
    initialValues: {
      name:"",
      email: "",
      password: "",
      confirmPassword: "",
    },
    enableReinitialize: false,
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Please Enter your name."),
      email: Yup.string()
        .matches(emailRegex, "Invalid email format")
        .email("Please Enter Valid Email")
        .required("Please Enter E-mail."),
      password: Yup.string()
        .matches(
          passwordRegex,
          "Password Must Contain min 8 Characters, 1 Letter, 1 Number and 1 special character:"
        )
        .required("Please Enter Your Password"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Required"),
    }),
    onSubmit: (values) => {
      console.log({name: values.name, mail: values.email, password: values.password });
    },
  });

  return (
    <NonAuthLayoutWrapper>
      <Row className=" justify-content-center my-auto">
        <Col sm={8} lg={6} xl={5} className="col-xxl-4">
          <div className="py-md-5 py-4">
            <AuthHeader
              title="Register Account"
              subtitle="Get your Beky.ai account now."
            />

            {/* {user && user ? (
              <Alert color="success">Register User Successfully</Alert>
            ) : null}

            {registrationError && registrationError ? (
              <Alert color="danger">{registrationError}</Alert>
            ) : null} */}

            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              className="position-relative"
            >
              {/* {regLoading && <Loader />} */}
              <Label htmlFor="name" className="form-label">
                Name
              </Label>
              <div className="mb-3">
                <Input
                  label="name"
                  type="text"
                  name="name"
                  placeholder="Enter name"
                  className="form-control"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                />
                {validation.touched.name && validation.errors.name ? (
                  <p style={{ color: "red" }}>{validation.errors.name}</p>
                ) : null}
              </div>
              <Label htmlFor="Email" className="form-label">
                Email
              </Label>
              <div className="mb-3">
                <Input
                  label="Email"
                  type="text"
                  name="email"
                  placeholder="Enter Email"
                  className="form-control"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                />
                {validation.touched.email && validation.errors.email ? (
                  <p style={{ color: "red" }}>{validation.errors.email}</p>
                ) : null}
              </div>
              <Label htmlFor="Password" className="form-label">
                Password
              </Label>
              <div className="mb-3">
                <Input
                  label="password"
                  type="password"
                  name="password"
                  placeholder="Enter password"
                  className="form-control"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.password || ""}
                />
                {validation.touched.password && validation.errors.password ? (
                  <p style={{ color: "red" }}>{validation.errors.password}</p>
                ) : null}
              </div>
              <Label htmlFor="confirmPassword" className="form-label">
                Confirm Password
              </Label>
              <div className="mb-3">
                <Input
                  label="confirmPassword"
                  type="password"
                  name="confirmPassword"
                  className="form-control pe-5"
                  placeholder="Confirm password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.confirmPassword || ""}
                />
                {validation.touched.confirmPassword &&
                validation.errors.confirmPassword ? (
                  <p style={{ color: "red" }}>
                    {validation.errors.confirmPassword}
                  </p>
                ) : null}
              </div>

              <div className="mb-4">
                <p className="mb-0">
                  By registering you agree to the Beky.ai{" "}
                  <Link to="#" className="text-primary">
                    Terms of Use
                  </Link>
                </p>
              </div>

              <div className="text-center mb-3">
                <Button
                  color="primary"
                  className="w-100  waves-effect waves-light"
                  type="submit"
                >
                  Register
                </Button>
              </div>
        
            </Form>

            <div className="mt-5 text-center text-muted">
              <p>
                Already have an account?{" "}
                <Link
                  to="/auth-login"
                  className="fw-medium text-decoration-underline"
                >
                  Login
                </Link>
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </NonAuthLayoutWrapper>
  );
};

export default Register;
