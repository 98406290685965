import React, { useEffect, useState } from "react";
import { NavItem, UncontrolledTooltip, NavLink } from "reactstrap";
import { useDispatch } from "react-redux";
import { themeActions } from "../redux/theme/ThemeSlice";

const LightDarkMode = ({ layoutMode }) => {
  const dispatch = useDispatch();

  const [themeMode, setThemeMode] = useState("light");
  function changeBodyAttribute(attribute, value) {
    if (document.body) document.body.setAttribute(attribute, value);
    return true;
  }
  useEffect(() => {
    changeBodyAttribute("data-layout-mode", themeMode);
    dispatch(
      themeActions.changeTheme({
        theme: themeMode,
      })
    );
  }, [themeMode]);

  return (
    <NavItem className="mt-auto" id="color-mode">
      <NavLink
        className="nav-link light-dark"
        onClick={() => {
          if (themeMode === "light") {
            setThemeMode("dark");
          } else {
            setThemeMode("light");
          }
        }}
      >
        <i className="bx bx-moon" id="moon"></i>{" "}
      </NavLink>{" "}
      <UncontrolledTooltip placement="right" target="color-mode">
        <span className="light-mode">Light</span>
        <span className="dark-mode">Dark</span> Mode
      </UncontrolledTooltip>
    </NavItem>
  );
};

export default LightDarkMode;
