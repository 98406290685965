import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  Tooltip,
  Legend,
  LinearScale,
} from "chart.js";

const BarChart = () => {
  ChartJS.register(BarElement, CategoryScale, Tooltip, LinearScale, Legend);

  const data = {
    labels: ["Mon", "Tue", "Wed"],
    datasets: [
      {
        label: "369",
        data: [3, 5, 43],
        backgroundColor: "#9A96FC",
        // borderColor: "black",
        // borderWidth: 1,
      },
    ],
  };
  const options = {};
  return (
    <div className="custom-chart">
      <Bar data={data} options={options} width={400} />
    </div>
  );
};

export default BarChart;
