import React from "react";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// components
import SideMenu from "./SideMenu";

const Index = (props) => {
  return (
    <div className="layout-wrapper d-lg-flex ">
      <SideMenu />

      {props.children}
      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default Index;
