import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
} from "reactstrap";
import UserView from "../pages/Dashbord/SuperAdmin/UserView";

const UsersPopup = ({ isOpen, onClose, onInvite }) => {
  const [tab, setTab] = useState(false);
  const [siteMap, setSiteMap] = useState("");
  const [sites, setSites] = useState([]);

  const handleSubmit = () => {
    console.log({ websiteval: sites });
  };

  const links = sites?.map((itm, idx) => (
    <li
      className="font-size-13 d-flex align-items-center gap-2"
      key={idx}
      style={{ listStyle: "none" }}
    >
      <i className="bx bx-link" style={{ color: "#4f46e5" }}></i>
      <p className="m-0">{itm}</p>
    </li>
  ));
  return (
    <Modal isOpen={isOpen} toggle={onClose} tabIndex={-1} centered scrollable>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
          return false;
        }}
      >
        <div className="tab-content px-3">
          <div className={`tab-pane ${!tab && "active"} `}>
            <UserView />
          </div>
          <div className={`tab-pane ${tab && "active"}`}>
            <div className="mb-3">
              <p style={{ marginBottom: "3px", fontSize: "px" }}>
                Website sitemap
              </p>
              <div className="d-flex">
                <Input
                  type="text"
                  className="form-control"
                  id="websiteval"
                  placeholder="Enter website sitemap"
                  onChange={(e) => setSiteMap(e.target.value)}
                  onBlur={(e) => setSiteMap(e.target.value)}
                  value={siteMap}
                />
                <Button
                  className=""
                  color="secondary"
                  onClick={() => {
                    if (siteMap !== "") {
                      setSites([...sites, siteMap]);
                    }
                    setSiteMap("");
                  }}
                >
                  Add
                </Button>
              </div>
              <ul style={{ padding: "0", marginTop: "15px", opacity: "0.7" }}>
                {links}
              </ul>
            </div>
          </div>
        </div>
        <ModalFooter>
          <Button type="button" color="link" className="btn" onClick={onClose}>
            Close
          </Button>
          <Button color="primary" type="submit">
            Update
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default UsersPopup;
