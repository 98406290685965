import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Form,Input } from "reactstrap";

import { UncontrolledTooltip } from "reactstrap";

import { Button, Collapse } from "reactstrap";
import SubscribtionCost from "./SubscribtionCost";
import AdminUsersSettings from "./AdminUsersSettings";
import Analytics from "./Analytics";
import classnames from "classnames";

import { SETTINGS_COLLAPSES } from "../../../constants/settings";
import UsersPopup from "../../../components/UsersPopup";

const SuperAdmin = () => {

  const AccordianItem = ({ item, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleCollapse = () => {
      if (isOpen) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    };

    return (
      <div className="accordion-item">
        <div className="accordion-header" id="headerpersonalinfo">
          <Button
            color="none"
            className={classnames(
              "accordion-button",
              "font-size-14",
              "fw-medium",

              { collapsed: !isOpen }
            )}
            onClick={toggleCollapse}
            type="button"
          >
            <i className={classnames("text-muted", "me-3", item.icon)}></i>{" "}
            {item.label}
          </Button>
        </div>
        <Collapse
          isOpen={isOpen}
          id="personalinfo"
          className="accordion-collapse"
        >
          {item.component}
        </Collapse>
      </div>
    );
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);
  const [selectedMenu, setSelectedMenu] = useState(null);

  const collapseItems = [
    {
      value: SETTINGS_COLLAPSES.ACSUBSCRIPTIONCOSTCOUNT,
      label: "Subscription cost",
      icon: "bx bx-dollar",
      component: <SubscribtionCost />,
    },
    {
      value: SETTINGS_COLLAPSES.ADMINUSERS,
      label: "Super Admins",
      icon: "bx bx-user",
      component: <AdminUsersSettings />,
    },
    {
      value: SETTINGS_COLLAPSES.ANALYTICS,
      label: "Platform Analytics",
      icon: "bx bx-line-chart",
      component: <Analytics />,
    },
  ];
  const onChangeCollapse = (id) => {
    const { SUBSCRIPTIONCOST } = SETTINGS_COLLAPSES;

    setSelectedMenu(id);
  };

  return (
    <>
      <div className="">
        <div className="d-flex align-items-start px-4 pt-4 ">
          <div className="flex-grow-1 d-flex justify-content-between">
            <h4 className="mb-4">Control Panel</h4>
            <div>
              <button
                type="button"
                className="btn btn-soft-primary btn-sm pb-0"
                id="inviteAdmin"
              >
                <i className="bx bx-plus"></i>
                <UncontrolledTooltip target={"inviteAdmin"} placement="bottom">
                  Invite Admin
                </UncontrolledTooltip>
              </button>
            </div>
          </div>
        </div>
        <div>
          <h4 className="px-3 mb-3 mt-2 font-size-11 text-muted text-uppercase">
            Platform settings
          </h4>
          <div id="settingprofile" className="accordion accordion-flush ">
            {(collapseItems || []).map((item, key) => (
              <AccordianItem
                item={item}
                key={key}
                onChange={onChangeCollapse}
              />
            ))}
          </div>
        </div>

        <div className="flex-grow-1 ">
          <h4 className="px-3 mb-3 mt-4 font-size-11 text-muted text-uppercase pl-5">
            Active Accounts
          </h4>
        </div>
        <Form className="px-3">
          <div className="input-group mb-3">
            <Input
              // onKeyUp={searchUsers}
              id="serachChatUser"
              type="text"
              className="form-control bg-light border-0 pe-0"
              placeholder="Search here.."
            />
            <Button color="light" type="button" id="searchbtn-addon">
              <i className="bx bx-search align-middle"></i>
            </Button>
          </div>
        </Form>

        <ul className="px-3" style={{ listStyle: "none" }}>
          <li style={{ height: "50px" }}>
            <Link to="#">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <div className="d-flex align-items-center gap-3">
                  <i
                    className="bx bx-user-circle font-size-20"
                    style={{ color: "red !imoirtant" }}
                  ></i>

                  <div className="overflow-hidden d-flex gap-5 ">
                    <h5 className="font-size-14 mb-0">Fresh</h5>
                    {/* <span className="font-size-12">Admin</span> */}
                  </div>
                </div>
                <i className="bx bxs-trash text-muted"></i>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <div className="d-flex align-items-center gap-3">
                  <i
                    className="bx bx-user-circle font-size-20"
                    style={{ color: "red !imoirtant" }}
                  ></i>

                  <div className="overflow-hidden d-flex gap-5 ">
                    <h5 className="font-size-14 mb-0">Orthoplex</h5>
                    {/* <span className="font-size-12">Admin</span> */}
                  </div>
                </div>
                <i className="bx bxs-trash text-muted"></i>
              </div>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SuperAdmin;
